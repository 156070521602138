import { Button, makeStyles, withStyles } from '@material-ui/core';
import React from 'react';
import GoogleLogo from '../../assets/icons/GoogleLogo.png';

const ButtonGoogle = () => {
    const classes = useStyles();
    return (
        <ColorButton variant="contained">
            <img src={GoogleLogo} alt={'GoogleLogo'} className={classes.googleIcon} />
            Sign in with Google
        </ColorButton>
    )
}

const ColorButton = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#F5F5F5',
        },
        borderRadius: 50,
        width: '100%',
        height: 50,
        fontSize: 14
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    googleIcon: {
        height: 20,
        marginRight: 5
    }
}));

export default ButtonGoogle
