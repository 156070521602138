import React, { Fragment, useState, useEffect } from 'react';
import { CircularProgress, makeStyles, Paper, Table, IconButton, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Collapse, Box, Typography, Button, withStyles, TableSortLabel } from '@material-ui/core';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import 'moment/locale/id';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TablePayment = ({ data = [], isLoading, refCode = "" }) => {

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const rowsPerPage = 20;
    const [dataLength, setDataLength] = useState(data.length);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('create_dtm');
    const location = useLocation();

    const [showedData, setShowedData] = useState([]);

    useEffect(
        () => {
            // const tempData = data.filter(x => x.referral_code.toLowerCase().includes(refCode.toLowerCase()));
            setShowedData(data);
            setDataLength(data.length);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const ColorButton = withStyles((theme) => ({
        root: {
            fontSize: 12,
            fontWeight: 'bold',
            color: theme.palette.getContrastText('#652f8e'),
            backgroundColor: '#652f8e',
            '&:hover': {
                backgroundColor: '#884bb7',
            },
            borderRadius: 50,
            width: '100%',
            height: 40,
            padding: 20
        },
    }))(Button);

    const Download = () => {
        const dateNow = moment().format('DD_MMM_YYYY_hh_mm_ss');
        return (
            <ExcelFile element={<ColorButton>Export</ColorButton>} filename={`Data Payment_${dateNow}`}>
                <ExcelSheet data={stableSort(showedData, getComparator(order, orderBy))} name="Payment">
                    <ExcelColumn label="User Id" value={(col) => `0${col.user_id.substring(3, col.user_id.length)}`} />
                    <ExcelColumn label="Date Transaction" value={(col) => moment(col.create_dtm.split('T')[0]).format('DD MMMM YYYY')} />
                    <ExcelColumn label="Payment Method" value="payment_method" />
                    <ExcelColumn label="Total Bill" value="total_bill" />
                    <ExcelColumn label="Total Payment" value="total_payment" />
                    <ExcelColumn label="Bank Account" value="bank_account" />
                    <ExcelColumn label="Bank Holder Name" value="bank_holder_name" />
                    <ExcelColumn label="Bank Name" value="bank_name" />
                </ExcelSheet>
            </ExcelFile>
        )
    }

    return (
        <div>
            <div className={classes.divHeader}>
                <div className={classes.divButtonExport}>
                    <Download />
                </div>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={dataLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                />
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell />
                            <TableCell className={classes.tableCellHeader}>
                                <TableSortLabel
                                    className={classes.fontStandard}
                                    active={orderBy === "user_id"}
                                    direction={orderBy === "user_id" ? order : "asc"}
                                    onClick={() => handleRequestSort("user_id")}
                                >
                                    User Id
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">
                                <TableSortLabel
                                    className={classes.fontStandard}
                                    active={orderBy === "create_dtm"}
                                    direction={orderBy === "create_dtm" ? order : "asc"}
                                    onClick={() => handleRequestSort("create_dtm")}
                                >
                                    Date Transaction
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">
                                <TableSortLabel
                                    className={classes.fontStandard}
                                    active={orderBy === "payment_method"}
                                    direction={orderBy === "payment_method" ? order : "asc"}
                                    onClick={() => handleRequestSort("payment_method")}
                                >
                                    Payment Method
                                </TableSortLabel>
                            </TableCell>
                            {location.pathname === '/onlinesales' ?
                                <TableCell className={classes.tableCellHeader} align="left">Payment Account</TableCell>
                                :
                                <TableCell className={classes.tableCellHeader} align="left">Payment Date</TableCell>
                            }
                            <TableCell className={classes.tableCellHeader} align="left">Total Bill</TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">Total Payment</TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">Bank Account</TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">Bank Holder Name</TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">Bank Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? stableSort(showedData, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : stableSort(showedData, getComparator(order, orderBy))
                        ).map((row, index) => (
                            <RowOnline key={index} row={row} />
                        )
                        )}
                    </TableBody>
                </Table>
                {!isLoading && data.length === 0 &&
                    <div className={classes.noData}>
                        No Data Available
                    </div>
                }
                {isLoading &&
                    <div className={classes.noData}>
                        <CircularProgress size={30} className={classes.buttonProgress} />
                    </div>
                }
            </TableContainer>
        </div>
    )
}

const RowOnline = ({ row }) => {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    return (
        <Fragment>
            <TableRow hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    0{row.user_id.substring(3, row.user_id.length)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.create_dtm !== "" ? moment(row.create_dtm.split('T')[0]).format('DD MMMM YYYY') : 'No Transaction'}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.payment_method}
                </TableCell>
                {location.pathname === '/onlinesales' ?
                    <TableCell align="left" className={classes.tableCell}>
                        {row.payment_account}
                    </TableCell>
                    :
                    <TableCell align="left" className={classes.tableCell}>
                        {row.payment_date}
                    </TableCell>
                }
                <TableCell align="left" className={classes.tableCell}>
                    {convertToRupiah(row.total_bill)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {convertToRupiah(row.total_payment)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.bank_account.split('|').map((item, i) => {
                        return <p key={i}>{item}</p>
                    })}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.bank_holder_name.split('|').map((item, i) => {
                        return <p key={i}>{item}</p>
                    })}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.bank_name.split('|').map((item, i) => {
                        return <p key={i}>{item}</p>
                    })}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#fbfbfb', }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {row.customer !== null &&
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Detail Customer
                                </Typography>
                                <div className={classes.rowCustomerDetail}>
                                    <Typography className={classes.rowTitle}>Name</Typography>
                                    <Typography className={classes.rowContent}>{row.customer !== null ? row.customer.name : ""}</Typography>
                                </div>
                                <div className={classes.rowCustomerDetail}>
                                    <Typography className={classes.rowTitle}>Email</Typography>
                                    <Typography className={classes.rowContent}>{row.customer !== null ? row.customer.email : ""}</Typography>
                                </div>
                                <div className={classes.rowCustomerDetail}>
                                    <Typography className={classes.rowTitle}>Phone</Typography>
                                    <Typography className={classes.rowContent}>{row.customer !== null ? row.customer.handphone : ""}</Typography>
                                </div>
                                <div className={classes.rowCustomerDetail}>
                                    <Typography className={classes.rowTitle}>Address</Typography>

                                    <Typography className={classes.rowContent}>{row.customer !== null ? `${row.customer.alamat}, ${row.customer.kecamatan}, ${row.customer.kodepos}` : ""}</Typography>
                                </div>
                            </Box>
                        }
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Products
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCellHeader}>Name</TableCell>
                                        <TableCell className={classes.tableCellHeader}>Amount</TableCell>
                                        <TableCell className={classes.tableCellHeader}>Discount</TableCell>
                                        <TableCell className={classes.tableCellHeader}>Price Per Item</TableCell>
                                        <TableCell className={classes.tableCellHeader}>Total price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.products !== null && row.products.map((product) => (
                                        <TableRow key={product.name}>
                                            <TableCell component="th" scope="row" className={classes.tableCell}>
                                                {product.name}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>{product.number_orders}</TableCell>
                                            {location.pathname === '/onlinesales' ?
                                                <TableCell className={classes.tableCell}>{convertToRupiah(product.discount_info.amount)}</TableCell>
                                                :
                                                <TableCell className={classes.tableCell}>{convertToRupiah(product.diskon)}</TableCell>
                                            }
                                            <TableCell className={classes.tableCell}>
                                                {convertToRupiah(product.sell_cost)}
                                            </TableCell>
                                            {location.pathname === '/onlinesales' ?
                                                <TableCell className={classes.tableCell}>
                                                    {convertToRupiah(Math.round(product.sell_cost * product.number_orders - product.discount_info.amount))}
                                                </TableCell>
                                                :
                                                <TableCell className={classes.tableCell}>
                                                    {convertToRupiah(Math.round(product.sell_cost * product.number_orders - product.diskon))}
                                                </TableCell>
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

// const Row = ({ row }) => {
//     const [open, setOpen] = useState(false);
//     const classes = useStyles();
//     return (
//         <Fragment>
//             <TableRow hover>
//                 <TableCell>
//                     <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
//                         {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//                     </IconButton>
//                 </TableCell>
//                 <TableCell align="left" className={classes.tableCell}>
//                     0{row.user_id.substring(3, row.user_id.length)}
//                 </TableCell>
//                 <TableCell align="left" className={classes.tableCell}>
//                     {row.Create_dtm !== "" ? moment(row.create_dtm).format('DD MMMM YYYY') : 'No Transaction'}
//                 </TableCell>
//                 <TableCell align="left" className={classes.tableCell}>
//                     {row.payment_method}
//                 </TableCell>
//                 <TableCell align="left" className={classes.tableCell}>
//                     {convertToRupiah(row.total_bill)}
//                 </TableCell>
//                 <TableCell align="left" className={classes.tableCell}>
//                     {convertToRupiah(row.total_payment)}
//                 </TableCell>
//             </TableRow>
//             <TableRow>
//                 <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#fbfbfb', }} colSpan={6}>
//                     <Collapse in={open} timeout="auto" unmountOnExit>
//                         <Box margin={1}>
//                             <Typography variant="h7" gutterBottom component="div">
//                                 Products
//                                   </Typography>
//                             <Table size="small" aria-label="purchases">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell className={classes.tableCellHeader}>Name</TableCell>
//                                         <TableCell className={classes.tableCellHeader}>Amount</TableCell>
//                                         <TableCell className={classes.tableCellHeader}>Discount</TableCell>
//                                         <TableCell className={classes.tableCellHeader}>Price Per Item</TableCell>
//                                         <TableCell className={classes.tableCellHeader}>Total price</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {row.products.map((product) => (
//                                         <TableRow key={product.name}>
//                                             <TableCell component="th" scope="row" className={classes.tableCell}>
//                                                 {product.name}
//                                             </TableCell>
//                                             <TableCell className={classes.tableCell}>{product.number_orders}</TableCell>
//                                             <TableCell className={classes.tableCell}>{convertToRupiah(product.diskon)}</TableCell>
//                                             <TableCell className={classes.tableCell}>
//                                                 {convertToRupiah(product.sell_cost)}
//                                             </TableCell>
//                                             <TableCell className={classes.tableCell}>
//                                                 {convertToRupiah(Math.round(product.sell_cost * product.number_orders - product.diskon))}
//                                             </TableCell>
//                                         </TableRow>
//                                     ))}
//                                 </TableBody>
//                             </Table>
//                         </Box>
//                     </Collapse>
//                 </TableCell>
//             </TableRow>
//         </Fragment>
//     )
// }

const convertToRupiah = (angka) => {
    var rupiah = '';
    var angkarev = angka.toString().split('').reverse().join('');
    for (var i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.';
    return 'Rp. ' + rupiah.split('', rupiah.length - 1).reverse().join('');
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    tableHead: {
        backgroundColor: '#f3f3f3',
    },
    table: {
        borderWidth: 1,
        borderColor: '#8898a8'
    },
    iconContact: {
        height: 20,
        aspectRatio: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableCellContacted: {
        padding: 8,
        width: '3%',
    },
    tableCell: {
        padding: 8,
        width: '13%',
        fontFamily: 'Poppins',
        fontSize: 12
    },
    tableCellHeader: {
        padding: 8,
        fontWeight: 'bold',
        width: '13%',
        fontFamily: 'Poppins',
        fontSize: 12
    },
    noData: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        fontFamily: 'Poppins',
        fontSize: 12
    },
    buttonProgress: {
        color: '#652f8e',
    },
    rowCustomerDetail: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: '#d3d3d3',
        border: 'solid'
    },
    rowTitle: {
        fontWeight: 'bold',
        width: 150,
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 12
    },
    rowContent: {
        fontSize: 12
    },
    divHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        marginBottom: 10,
        alignItems: 'center',
    },
    fontStandard: {
        fontSize: 12
    }
}));

export default TablePayment
