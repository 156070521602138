import axios from 'axios';

const instance = axios.create({
    // baseURL: 'http://sleeping-merchant-dev.ap-southeast-1.elasticbeanstalk.com/api'
    baseURL: 'https://artaka999.com/api'
});

instance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    },
);

export default instance;
