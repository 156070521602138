import React, { useState, useContext, useEffect } from 'react';
import Logo from '../../assets/images/LogoPembukuanDigitalWText.png';
import RedIllustration from '../../assets/images/redbg.jpg';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress, Grid, Hidden, TextField, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { Context } from '../../context/UpdatePasswordContext';

const ResetPassword = () => {
    const { state: { errorMessage, isLoading }, updatePassword } = useContext(Context);
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [username, setUsername] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [resetPassword, setResetPassword] = useState('');
    const [resetConfirmPassword, setResetConfirmPassword] = useState('');

    const [touchedPassword, setTouchedPassword] = useState(false);
    const [touchedConfirmPassword, setTouchedConfirmPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [touchedUsername, setTouchedUsername] = useState(false);
    const [touchedOwnerName, setTouchedOwnerName] = useState(false);
    const [errorUsername, setErrorUsername] = useState('');
    const [errorOwnerName, setErrorOwnerName] = useState('');
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;

    useEffect(
        () => {
            document.title = 'Pembukuan Digital';
            var link = document.querySelector("link[rel~='icon']")
            link.href = "/LogoPD.png";
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const checkPassword = () => {
        setTouchedPassword(true);
        if (resetPassword === "") {
            setErrorPassword('Password is required');
        } else if (!resetPassword.match(upperCaseLetters)) {
            setErrorPassword('Password must contain 1 uppercase letter');
        } else if (!resetPassword.match(lowerCaseLetters)) {
            setErrorPassword('Password must contain 1 lowercase letter');
        } else if (!resetPassword.match(numbers)) {
            setErrorPassword('Password must contain 1 number');
        } else if (resetPassword.length < 8) {
            setErrorPassword('Password must be at least 8 characters');
        } else {
            setErrorPassword('');
        }
    }
    const checkConfirmPassword = () => {
        setTouchedConfirmPassword(true);
        if (resetConfirmPassword === "") {
            setErrorConfirmPassword('Confirm Password is required');
        } else if (resetConfirmPassword !== resetPassword) {
            setErrorConfirmPassword('Password did not match');
        } else {
            setErrorConfirmPassword('');
        }
    }

    const checkUsername = () => {
        setTouchedUsername(true);
        const listNumber = ['0811', '0812', '0813', '0821', '0822', '0823', '0851', '0852', '0853']
        const checkNumber = listNumber.includes(username.substring(0, 4))
        if (username === "") {
            setErrorUsername('Username is required');
        } else if (!checkNumber) {
            setErrorUsername('Username is not a Telkomsel number');
        } else if (username.length < 10) {
            setErrorUsername('Username must be at least 10 characters');
        } else if (username.length > 12) {
            setErrorUsername('Username max 12 characters');
        } else {
            setErrorUsername('');
        }
    }

    const checkOwnerName = () => {
        setTouchedOwnerName(true);
        if (ownerName === "") {
            setErrorOwnerName('Owner Name is required');
        } else {
            setErrorOwnerName('');
        }
    }

    const onResetPasswordClicked = () => {
        checkUsername();
        checkOwnerName();
        checkPassword();
        checkConfirmPassword();
        if (errorPassword === '' && errorConfirmPassword === '' && errorUsername === '' && errorOwnerName === '') {
            const token = location.pathname.split('/')[2] || '';
            // updatePassword({ token, password: resetPassword, onSuccess: () => history.replace('/apps') });
            updatePassword({ token, user_id: username, owner_name: ownerName, password: resetPassword, onSuccess: () => history.replace('/addMerchant/' + token) });
        }
    }

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={12} sm={12} md={6} className={classes.leftSide}>
                <form className={classes.form}>
                    <div className={classes.divLogo}>
                        <img className={classes.iconLogo} alt={"icon"} src={Logo} />
                    </div>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Username
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            name="username"
                            type="number"
                            placeholder="Nomor Telepon"
                            onBlur={() => checkUsername()}
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                            error={touchedUsername && errorUsername !== ""}
                            helperText={touchedUsername && errorUsername}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Owner Name
                        </InputLabel>
                        <div style={{ padding: 5 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="owner-name"
                            name="owner-name"
                            onBlur={() => checkOwnerName()}
                            value={ownerName}
                            onChange={event => setOwnerName(event.target.value)}
                            error={touchedOwnerName && errorOwnerName !== ""}
                            helperText={touchedOwnerName && errorOwnerName}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Password
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="password"
                            onBlur={() => checkPassword()}
                            value={resetPassword}
                            onChange={event => setResetPassword(event.target.value)}
                            error={touchedPassword && errorPassword !== ""}
                            helperText={touchedPassword && errorPassword}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Confirm Password
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            name="confirm-password"
                            type="password"
                            id="confirm-password"
                            onBlur={() => checkConfirmPassword()}
                            value={resetConfirmPassword}
                            onChange={event => setResetConfirmPassword(event.target.value)}
                            error={touchedConfirmPassword && errorConfirmPassword !== ""}
                            helperText={touchedConfirmPassword && errorConfirmPassword}
                        />
                    </FormControl>
                    <div className={classes.boxNotes}>
                        <ul>
                            <li className={classes.textList}>Username digunakan untuk login di aplikasi Pembukuan Digital</li>
                            <li className={classes.textList}>Password harus memiliki paling sedikit 8 karakter</li>
                            <li className={classes.textList}>Password harus berisi kombinasi huruf besar, huruf kecil, dan angka</li>
                        </ul>
                    </div>
                    {errorMessage.length > 0 &&
                        <div className={classes.divError}>
                            {errorMessage}
                        </div>
                    }
                    <div className={classes.buttonVertical}>
                        <div className={classes.divButtonPrimary}>
                            <ColorButton variant="contained" onClick={onResetPasswordClicked} disabled={isLoading}>
                                SET PASSWORD
                            </ColorButton>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>
                </form>
            </Grid>
            <Grid item xs={false} sm={false} md={6}>
                <Hidden smDown>
                    <img src={RedIllustration} alt={"illustration"} className={classes.imgRight} />
                </Hidden>
            </Grid>
        </Grid>
    )
}

const ColorButton = withStyles((theme) => ({
    root: {
        fontWeight: 'bold',
        color: theme.palette.getContrastText('#FF0025'),
        backgroundColor: '#FF0025',
        '&:hover': {
            backgroundColor: '#FF0000',
        },
        borderRadius: 50,
        width: '100%',
        height: 50
    },
}))(Button);

const FieldInputLogin = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#b9093e',
                fontFamily: 'TelkomselBatik',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        fontFamily: 'Poppins'
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form: {
        width: '60%', // Fix IE 11 issue.
    },
    divLogo: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    iconLogo: {
        height: 80
    },
    formControl: {
        width: '100%',
        marginBottom: 10
    },
    labelInput: {
        fontSize: 20,
        color: '#000',
        fontFamily: 'TelkomselBatik'
    },
    buttonVertical: {
        display: 'flex',
        flexDirection: 'column'
    },
    divError: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        borderWidth: 1,
        borderColor: 'red',
        border: 'solid',
        color: 'red',
        marginBottom: 15
    },
    divButtonPrimary: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    buttonProgress: {
        color: '#652f8e',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    imgRight: {
        width: '100%',
        height: '100%'
    },
    textList: {
        fontSize: 14,
        fontFamily: 'TelkomselBatik'
    },
    boxNotes: {
        borderWidth: 1,
        borderColor: 'black',
        border: 'solid',
        marginBottom: 20
    }
}));

export default ResetPassword
