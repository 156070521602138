import React, { useContext, useState, useEffect } from 'react';
import Logo from '../../assets/images/Logo.png';
import Illustration from '../../assets/images/illustration.svg';
import ButtonPrimary from '../../components/Button/ButtonPrimary';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress, Grid, Hidden, Snackbar, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Context } from '../../context/AuthContext';
import { Alert } from '@material-ui/lab';
import { useHistory, useLocation } from 'react-router-dom';
// import Autocomplete from '@material-ui/lab/Autocomplete';

const Register = () => {
    const classes = useStyles();
    const history = useHistory();
    const { state: { errorMessage, isLoading, open, listRefCode, stopGet }, registerAdmin, updateOpen, registerNonAdmin, getRefCode } = useContext(Context);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [touchedFullname, setTouchedFullname] = useState(false);
    const [touchedEmail, setTouchedEmail] = useState(false);
    const [touchedPhone, setTouchedPhone] = useState(false);
    const [touchedPassword, setTouchedPassword] = useState(false);
    const [touchedConfirmPassword, setTouchedConfirmPassword] = useState(false);
    const [touchedReferralCode, setTouchedReferralCode] = useState(false);
    const [errorFullname, setErrorFullname] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorPhone, setErrorPhone] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [errorReferralCode, setErrorReferralCode] = useState('');
    const location = useLocation();
    const [isAdmin, setIsAdmin] = useState(false);

    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;

    useEffect(
        () => {
            const userField = localStorage.getItem('user_reg') || '';
            const emailField = localStorage.getItem('email_reg') || '';
            const phoneField = localStorage.getItem('phone_reg') || '';
            const passField = localStorage.getItem('pass_reg') || '';
            const conPassField = localStorage.getItem('con_pass_reg') || '';
            const refCodeField = localStorage.getItem('ref_code_reg') || '';
            setFullname(userField);
            setEmail(emailField);
            setPhone(phoneField);
            setPassword(passField);
            setConfirmPassword(conPassField);
            setReferralCode(refCodeField);

            if (location.pathname.includes('/admin')) {
                setIsAdmin(true);

            } else {
                setIsAdmin(false);
            }
            if (!stopGet)
                getRefCode();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onRegisterClicked = () => {
        if (isAdmin) {
            checkFullname();
            checkEmail();
            checkPhone();
            checkPassword();
            checkConfirmPassword();
            if (errorFullname === '' && errorEmail === '' && errorPhone === '' && errorPassword === '' && errorConfirmPassword === '' && email !== '' && phone !== '' && fullname !== '' && password !== '' && confirmPassword !== '') {
                localStorage.setItem('user_reg', fullname);
                localStorage.setItem('email_reg', email);
                localStorage.setItem('phone_reg', phone);
                localStorage.setItem('pass_reg', password);
                localStorage.setItem('con_pass_reg', confirmPassword);
                registerAdmin({ fullname, email, phone, password });
            }
        } else {
            checkFullname();
            checkEmail();
            checkPhone();
            checkPassword();
            checkConfirmPassword();
            checkReferralCode()
            if (errorFullname === '' && errorEmail === '' && errorPhone === '' && errorPassword === '' && errorConfirmPassword === '' && errorReferralCode === '' && email !== '' && phone !== '' && fullname !== '' && password !== '' && confirmPassword !== '' && referralCode !== '') {
                localStorage.setItem('user_reg', fullname);
                localStorage.setItem('email_reg', email);
                localStorage.setItem('phone_reg', phone);
                localStorage.setItem('pass_reg', password);
                localStorage.setItem('con_pass_reg', confirmPassword);
                localStorage.setItem('ref_code_reg', referralCode);
                registerNonAdmin({ fullname, email, phone, password, referralCode });
            }
        }
    }

    const handleClose = () => {
        updateOpen();
        history.push('/');
    }

    const checkReferralCode = () => {
        setTouchedReferralCode(true);
        if (referralCode === "") {
            setErrorReferralCode('Referral Code is required');
        } else if (listRefCode.filter(x => x.Referral_code === referralCode.toLowerCase()).length === 0) {
            setErrorReferralCode('Referral Code not available');
        } else {
            setErrorReferralCode('');
        }
    }

    const checkFullname = () => {
        setTouchedFullname(true);
        if (fullname === "") {
            setErrorFullname('Full name is required');
        } else {
            setErrorFullname('');
        }
    }
    const checkEmail = () => {
        setTouchedEmail(true);
        if (email === "") {
            setErrorEmail('Email is required');
        } else if (!(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {
            setErrorEmail('Invalid Email')
        } else {
            setErrorEmail('');
        }
    }
    const checkPhone = () => {
        setTouchedPhone(true);
        if (phone === "") {
            setErrorPhone('Phone is required');
        } else if (phone.length < 10) {
            setErrorPhone('Phone Number must be at least 10 characters');
        } else if (phone.length > 15) {
            setErrorPhone('Phone Number max 15 characters');
        } else {
            setErrorPhone('');
        }
    }
    const checkPassword = () => {
        setTouchedPassword(true);
        if (password === "") {
            setErrorPassword('Password is required');
        } else if (!password.match(upperCaseLetters)) {
            setErrorPassword('Password must contain 1 uppercase letter');
        } else if (!password.match(lowerCaseLetters)) {
            setErrorPassword('Password must contain 1 lowercase letter');
        } else if (!password.match(numbers)) {
            setErrorPassword('Password must contain 1 number');
        } else if (password.length < 6) {
            setErrorPassword('Password must be at least 6 characters');
        } else {
            setErrorPassword('');
        }
    }
    const checkConfirmPassword = () => {
        setTouchedConfirmPassword(true);
        if (confirmPassword === "") {
            setErrorConfirmPassword('Confirm Password is required');
        } else if (confirmPassword !== password) {
            setErrorConfirmPassword('Password did not match');
        } else {
            setErrorConfirmPassword('');
        }
    }

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={12} sm={12} md={6} className={classes.leftSide}>
                <form className={classes.form}>
                    <div className={classes.divLogo}>
                        <img className={classes.iconLogo} alt={"icon"} src={Logo} />
                    </div>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Full Name
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputRegister
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            name="name"
                            onBlur={() => checkFullname()}
                            value={fullname}
                            onChange={event => setFullname(event.target.value)}
                            error={touchedFullname && errorFullname !== ""}
                            helperText={touchedFullname && errorFullname}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Email
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputRegister
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            onBlur={() => checkEmail()}
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            error={touchedEmail && errorEmail !== ""}
                            helperText={touchedEmail && errorEmail}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Phone
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputRegister
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            name="phone"
                            type="number"
                            onBlur={() => checkPhone()}
                            value={phone}
                            onChange={event => setPhone(event.target.value)}
                            error={touchedPhone && errorPhone !== ""}
                            helperText={touchedPhone && errorPhone}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Password
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputRegister
                            className={classes.inputField}
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onBlur={() => checkPassword()}
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            error={touchedPassword && errorPassword !== ""}
                            helperText={touchedPassword && errorPassword}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Confirm Password
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputRegister
                            className={classes.inputField}
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="confirm-password"
                            autoComplete="current-password"
                            onBlur={() => checkConfirmPassword()}
                            value={confirmPassword}
                            onChange={event => setConfirmPassword(event.target.value)}
                            error={touchedConfirmPassword && errorConfirmPassword !== ""}
                            helperText={touchedConfirmPassword && errorConfirmPassword}
                        />
                    </FormControl>
                    {!isAdmin &&
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                                Referral Code
                        </InputLabel>
                            <div style={{ padding: 2 }} />
                            <FieldInputRegister
                                variant="outlined"
                                size="small"
                                margin="normal"
                                required
                                fullWidth
                                id="referralCode"
                                name="referralCode"
                                onBlur={() => checkReferralCode()}
                                value={referralCode}
                                onChange={event => setReferralCode(event.target.value)}
                                error={touchedReferralCode && errorReferralCode !== ""}
                                helperText={touchedReferralCode && errorReferralCode}
                            />
                            {/* <Autocomplete
                                id="combo-box-demo"
                                options={listRefCode}
                                getOptionLabel={(option) => option.Referral_code}
                                disableClearable
                                freeSolo
                                inputValue={referralCode}
                                onChange={(event, value) => { setReferralCode(value.Referral_code) }}
                                //   style={{ width: 300 }}
                                renderInput={(params) =>
                                    <FieldInputRegister
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="referralCode"
                                        name="referralCode"
                                        onBlur={() => checkReferralCode()}
                                        value={referralCode}
                                        onChange={event => setReferralCode(event.target.value)}
                                        error={touchedReferralCode && errorReferralCode !== ""}
                                        helperText={touchedReferralCode && errorReferralCode}
                                    />
                                }
                            /> */}

                        </FormControl>
                    }
                    {errorMessage.length > 0 &&
                        <div className={classes.divError}>
                            {errorMessage}
                        </div>
                    }
                    <div className={classes.divButton}>
                        <ButtonPrimary text="REGISTER" onClick={onRegisterClicked} disabled={isLoading} />
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </form>
            </Grid>
            <Grid item xs={false} sm={false} md={6}>
                <Hidden smDown>
                    <img src={Illustration} alt={"illustration"} className={classes.imgRight} />
                </Hidden>
            </Grid>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Register Succeess!
                </Alert>
            </Snackbar>
        </Grid>
    )
}

const FieldInputRegister = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#652f8e',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        fontFamily: 'Poppins'
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
    },
    form: {
        width: '60%', // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    divLogo: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    iconLogo: {
        height: 80
    },
    formControl: {
        width: '100%',
        marginBottom: 10
    },
    labelInput: {
        fontSize: 20,
        color: '#000',
    },
    divButton: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    divError: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        borderWidth: 1,
        borderColor: 'red',
        border: 'solid',
        color: 'red',
        marginBottom: 15
    },
    buttonProgress: {
        color: '#652f8e',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    imgRight: {
        width: '100%',
        height: '100%'
    }
}));

export default Register
