import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Provider as AuthProvider } from './context/AuthContext';
import { Provider as SleepingProvider } from './context/SleepingContext';
import { Provider as PaymentProvider } from './context/PaymentContext';
import { Provider as SubscriberProvider } from './context/SubscriberContext';
import { Provider as UpdatePasswordProvider } from './context/UpdatePasswordContext';
import { Provider as AddMerchantProvider } from './context/AddMerchantContext';

ReactDOM.render(
    <UpdatePasswordProvider>
        <AddMerchantProvider>
            <AuthProvider>
                <SleepingProvider>
                    <PaymentProvider>
                        <SubscriberProvider>
                            <App />
                        </SubscriberProvider>
                    </PaymentProvider>
                </SleepingProvider>
            </AuthProvider>
        </AddMerchantProvider>
    </UpdatePasswordProvider>
    , document.querySelector('#root'));