import createDataContext from './createDataContext';
import axios from 'axios';

const UpdatePasswordReducer = (state, action) => {
    switch (action.type) {
        case 'is_loading':
            return { ...state, isLoading: action.payload, errorMessage: '' };
        case 'add_error':
            return { ...state, errorMessage: action.payload, isLoading: false };
        default:
            return state;
    }
};

const updatePassword = dispatch => async ({ token, password, user_id, owner_name, onSuccess }) => {
    dispatch({ type: 'is_loading', payload: true });
    if (token === '') {
        dispatch({ type: 'add_error', payload: "Token not available" });
    } else {
        try {
            // const response = await resetPassword.post('/v1/updatePassword', { token, password });
            const options = { headers: { 'Authorization': `Bearer ${token}` } };
            const response = await axios.create({ baseURL: 'https://artaka999.com/api' }).post('/merchant/update', { user_id, secret_password: password, owner_name }, options);
            console.log(response);
            if (response.data.status.toLowerCase() === "failed") {
                dispatch({ type: 'add_error', payload: response.data.error });
            } else if (response.data.status.toLowerCase() === "success") {
                onSuccess();
            }
        } catch (err) {
            dispatch({
                type: 'add_error',
                payload: 'Something went wrong with update password'
            })
        }
    }
}

export const { Provider, Context } = createDataContext(
    UpdatePasswordReducer,
    { updatePassword },
    { errorMessage: '', isLoading: false }
)
