import React, { useState, useContext, useEffect } from 'react';
import Logo from '../../assets/images/Logo.png';
import Illustration from '../../assets/images/illustration.svg';
import ButtonPrimary from '../../components/Button/ButtonPrimary';
import ButtonSecondary from '../../components/Button/ButtonSecondary';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress, Grid, Hidden, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ButtonGoogle from '../../components/Button/ButtonGoogle';
import { useHistory } from 'react-router-dom';
import { Context } from '../../context/AuthContext';

const Login = () => {
    const { state: { errorMessage, isLoading }, login, changePage } = useContext(Context);
    const classes = useStyles();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(
        () => {
            const userField = localStorage.getItem('user_login') || '';
            const passField = localStorage.getItem('pass_login') || '';
            setUsername(userField);
            setPassword(passField);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onLoginClicked = () => {
        localStorage.setItem('user_login', username);
        localStorage.setItem('pass_login', password);
        login({ username, password, onSuccess: () => history.push('/sleeping') });
    }

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={12} sm={12} md={6} className={classes.leftSide}>
                <form className={classes.form}>
                    <div className={classes.divLogo}>
                        <img className={classes.iconLogo} alt={"icon"} src={Logo} />
                    </div>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Email or Phone
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Password
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                        />
                    </FormControl>
                    {errorMessage.length > 0 &&
                        <div className={classes.divError}>
                            {errorMessage}
                        </div>
                    }
                    <div className={classes.buttonVertical}>
                        <div className={classes.divButtonPrimary}>
                            <ButtonPrimary text="LOG IN" onClick={onLoginClicked} disabled={isLoading} />
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                        <div style={{ padding: 5 }} />
                        <ButtonSecondary text="REGISTER" onClick={() => {
                            changePage();
                            history.push('/register')
                        }} />
                        <div style={{ padding: 5 }} />
                        <ButtonGoogle />
                    </div>
                </form>
            </Grid>
            <Grid item xs={false} sm={false} md={6}>
                <Hidden smDown>
                    <img src={Illustration} alt={"illustration"} className={classes.imgRight} />
                </Hidden>
            </Grid>
        </Grid>
    )
}

const FieldInputLogin = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#652f8e',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        fontFamily: 'Poppins'
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form: {
        width: '60%', // Fix IE 11 issue.
    },
    divLogo: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    iconLogo: {
        height: 80
    },
    formControl: {
        width: '100%',
        marginBottom: 10
    },
    labelInput: {
        fontSize: 20,
        color: '#000',
    },
    buttonVertical: {
        display: 'flex',
        flexDirection: 'column'
    },
    divError: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        borderWidth: 1,
        borderColor: 'red',
        border: 'solid',
        color: 'red',
        marginBottom: 15
    },
    divButtonPrimary: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    buttonProgress: {
        color: '#652f8e',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    imgRight: {
        width: '100%',
        height: '100%'
    }
}));

export default Login
