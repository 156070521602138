import createDataContext from './createDataContext';
import api from '../api/api';

const AuthReducer = (state, action) => {
    switch (action.type) {
        case 'login':
            return { ...state, data: action.payload, errorMessage: '', isLoading: false };
        case 'add_error':
            return { ...state, errorMessage: action.payload, isLoading: false }
        case 'is_loading':
            return { ...state, isLoading: action.payload, errorMessage: '' }
        case 'change_page':
            return { ...state, errorMessage: '', isLoading: false }
        case 'get_from_local':
            return { ...state, data: action.payload }
        case 'logout':
            return { ...state, data: [], isLoading: false }
        case 'success_register':
            return { ...state, open: true }
        case 'change_open':
            return { ...state, open: false }
        case 'get_referral_code':
            return { ...state, listRefCode: action.payload, stopGet: true }
        case 'stop_get':
            return { ...state, stopGet: true }
        default:
            return state;
    }
};

const login = dispatch => async ({ username, password, onSuccess }) => {
    dispatch({ type: 'is_loading', payload: true });
    try {
        let response;
        if (username.includes('@'))
            response = await api.post('/admin/login', { email: username, secret_password: password, referral_code: "" });
        else
            response = await api.post('/admin/login', { phone: username, secret_password: password, referral_code: "" });
        if (response.data.status === "Failed") {
            dispatch({ type: 'add_error', payload: response.data.error })
        } else if (response.data.status === "Success") {
            localStorage.clear();
            localStorage.setItem('token', response.data.response.token);
            localStorage.setItem('profile_name', response.data.response.username);
            localStorage.setItem('profile_email', response.data.response.email);
            localStorage.setItem('profile_id', response.data.response.id);
            localStorage.setItem('profile_phone', response.data.response.phone);
            localStorage.setItem('referral_code', response.data.response.referral_code);
            localStorage.setItem('role', response.data.response.role);
            dispatch({ type: 'login', payload: response.data.response });
            onSuccess();
        }
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with sign up'
        })
    }
}

const loginNonAdmin = dispatch => async ({ username, password, referralCode, onSuccess }) => {
    dispatch({ type: 'is_loading', payload: true });
    try {
        let response;
        if (username.includes('@'))
            response = await api.post('/admin/login', { email: username, secret_password: password, referral_code: referralCode });
        else
            response = await api.post('/admin/login', { phone: username, secret_password: password, referral_code: referralCode });
        if (response.data.status === "Failed") {
            dispatch({ type: 'add_error', payload: response.data.error })
        } else if (response.data.status === "Success") {
            localStorage.setItem('token', response.data.response.token);
            localStorage.setItem('profile_name', response.data.response.username);
            localStorage.setItem('profile_email', response.data.response.email);
            localStorage.setItem('profile_id', response.data.response.id);
            localStorage.setItem('profile_phone', response.data.response.phone);
            localStorage.setItem('referral_code', response.data.response.referral_code);
            localStorage.setItem('role', response.data.response.role);
            dispatch({ type: 'login', payload: response.data.response });
            onSuccess();
        }
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with sign up'
        })
    }
}

const tryLocalLogin = dispatch => async () => {
    const token = localStorage.getItem('token');
    if (token) {
        const username = localStorage.getItem('profile_name');
        const email = localStorage.getItem('profile_email');
        const phone = localStorage.getItem('profile_phone');
        const id = localStorage.getItem('id');
        const referral_code = localStorage.getItem('referral_code');
        const role = localStorage.getItem('role');
        const profile = { username, email, phone, token, id, referral_code, role };
        dispatch({ type: 'get_from_local', payload: profile });
    }
}

const registerAdmin = dispatch => async ({ fullname, email, phone, password }) => {
    dispatch({ type: 'is_loading', payload: true })
    try {
        const response = await api.post('/admin/register', { username: fullname, email, phone, secret_password: password });
        if (response.data.status === "Failed") {
            dispatch({ type: 'add_error', payload: response.data.error });
        } else if (response.data.status === "Success") {
            localStorage.clear();
            dispatch({ type: 'change_page' });
            dispatch({ type: 'success_register' });
        }
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with sign up'
        })
    }
}

const registerNonAdmin = dispatch => async ({ fullname, email, phone, password, referralCode }) => {
    dispatch({ type: 'is_loading', payload: true })
    try {
        const response = await api.post('/admin/register', { username: fullname, email, phone, secret_password: password, referral_code: referralCode });
        if (response.data.status === "Failed") {
            dispatch({ type: 'add_error', payload: response.data.error });
        } else if (response.data.status === "Success") {
            localStorage.clear();
            dispatch({ type: 'change_page' });
            dispatch({ type: 'success_register' });
        }
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with sign up'
        })
    }
}

const logout = dispatch => ({ goLogout }) => {
    dispatch({ type: 'logout' })
    localStorage.clear();
    goLogout();
}

const changePage = dispatch => () => {
    dispatch({ type: 'change_page' });
    localStorage.clear();
}

const updateOpen = dispatch => () => {
    dispatch({ type: 'change_open' });
}

const getRefCode = dispatch => async () => {
    try {
        const response = await api.post('/admin/ShowReferral');
        if (response.data.status === "Success") {
            dispatch({ type: 'get_referral_code', payload: response.data.response });
        }
    } catch (err) {
        dispatch({
            type: 'stop_get'
        })
    }
}

export const { Provider, Context } = createDataContext(
    AuthReducer,
    { login, loginNonAdmin, registerAdmin, registerNonAdmin, changePage, tryLocalLogin, logout, updateOpen, getRefCode },
    { data: [], errorMessage: '', isLoading: false, open: false, listRefCode: [], stopGet: false }
)