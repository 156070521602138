import { Button, Collapse, List, ListItem, ListItemText, ListItemIcon, makeStyles, withStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import LogoDashboard from '../../assets/images/LogoDashboard.png';
import { Context } from '../../context/SleepingContext';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as PaymentContext } from '../../context/PaymentContext';
import { Context as SubscriberContext } from '../../context/SubscriberContext';
import DownArrowWhite from '../../assets/icons/DownArrowWhite.png';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PaymentIcon from '@material-ui/icons/Payment';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

const Sidebar = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [openPayment, setOpenPayment] = useState(true);
    const [openSubscribers, setOpenSubscribers] = useState(true);
    const { state: { activeTab }, setActiveTab, setEmptyArraySleep } = useContext(Context);
    const { setEmptyArrayPayment } = useContext(PaymentContext);
    const { setEmptyArraySub } = useContext(SubscriberContext);
    const { state: { data }, logout } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(
        () => {
            if (location.pathname === '/sleeping')
                setActiveTab(0)
            else if (location.pathname === '/already')
                setActiveTab(1)
            else if (location.pathname === '/notcontact')
                setActiveTab(2)
            else if (location.pathname === '/onlinesales')
                setActiveTab(3)
            else if (location.pathname === '/onlinesalesqris')
                setActiveTab(5)
            else if (location.pathname === '/subscribers' || location.pathname === '/detailSubscriber')
                setActiveTab(4)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickPayment = () => {
        setOpenPayment(!openPayment);
    };

    const handleClickSubscribers = () => {
        setOpenSubscribers(!openSubscribers);
    };

    const clickedTab = (index) => {
        localStorage.setItem('last_page', 0);
        setActiveTab(index);

        if (index === 0) {
            history.push('/sleeping')
        } else if (index === 1) {
            history.push('/already')
        } else if (index === 2) {
            history.push('/notcontact')
        } else if (index === 3) {
            history.push('/onlinesales')
        } else if (index === 4) {
            history.push('/subscribers')
        } else if (index === 5) {
            history.push('/onlinesalesqris')
        }
    }

    const handleClickPopup = () => {
        setOpenPopup(!openPopup);
    };

    const onLogoutClicked = () => {
        setEmptyArraySleep();
        setEmptyArrayPayment();
        setEmptyArraySub();
        logout({ goLogout: () => history.push('/') });
    }

    return (
        <div className={classes.root}>
            <div className={classes.topSection}>
                <div className={classes.topLogo}>
                    <img src={LogoDashboard} alt="logo-dashboard" className={classes.imgLogo} />
                </div>
                <List className={classes.listRoot}>
                    <ListItem button onClick={handleClick} className={classes.listItemHead} focusVisibleClassName={classes.focusVisible}>
                        <ListItemIcon className={classes.listIcon}>
                            <VisibilityOffIcon className={classes.menuIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.textListHead}>
                            <div className={classes.fontMenu}>
                                Sleeping
                            </div>
                        </ListItemText>
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.listItem}>
                            <ListItem onClick={() => clickedTab(0)} button className={activeTab === 0 ? classes.nestedActive : classes.nested}>
                                <ListItemIcon className={classes.listIcon}>
                                    <VisibilityOffOutlinedIcon className={classes.menuIcon} />
                                </ListItemIcon>
                                <ListItemText className={classes.textList}>
                                    <div className={classes.fontMenu}>
                                        Sleeping Merchant
                                    </div>
                                </ListItemText>
                            </ListItem>
                            <ListItem onClick={() => clickedTab(1)} button className={activeTab === 1 ? classes.nestedActive : classes.nested}>
                                <ListItemIcon className={classes.listIcon}>
                                    <CheckCircleOutlineOutlinedIcon className={classes.menuIcon} />
                                </ListItemIcon>
                                <ListItemText className={classes.textList}>
                                    <div className={classes.fontMenu}>
                                        Already Contacted
                                    </div>
                                </ListItemText>
                            </ListItem>
                            <ListItem onClick={() => clickedTab(2)} button className={activeTab === 2 ? classes.nestedActive : classes.nested}>
                                <ListItemIcon className={classes.listIcon}>
                                    <ErrorOutlineOutlinedIcon className={classes.menuIcon} />
                                </ListItemIcon>
                                <ListItemText className={classes.textList}>
                                    <div className={classes.fontMenu}>
                                        Not Contacted Yet
                                    </div>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem onClick={handleClickPayment} button className={classes.listItemHead}>
                        <ListItemIcon className={classes.listIcon}>
                            <PaymentIcon className={classes.menuIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.textListHead}>
                            <div className={classes.fontMenu}>
                                QRIS & VA Payment
                            </div>
                        </ListItemText>
                    </ListItem>
                    <Collapse in={openPayment} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.listItem}>
                            <ListItem onClick={() => clickedTab(3)} button className={activeTab === 3 ? classes.nestedActive : classes.nested}>
                                <ListItemIcon className={classes.listIcon}>
                                    <DevicesOutlinedIcon className={classes.menuIcon} />
                                </ListItemIcon>
                                <ListItemText className={classes.textList}>
                                    <div className={classes.fontMenu}>
                                        Online Sales
                                    </div>
                                </ListItemText>

                            </ListItem>
                            <ListItem onClick={() => clickedTab(5)} button className={activeTab === 5 ? classes.nestedActive : classes.nested}>
                                <ListItemIcon className={classes.listIcon}>
                                    <DevicesOutlinedIcon className={classes.menuIcon} />
                                </ListItemIcon>
                                <ListItemText className={classes.textList}>
                                    <div className={classes.fontMenu}>
                                        Online Sales QRIS
                                    </div>
                                </ListItemText>

                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem onClick={handleClickSubscribers} button className={classes.listItemHead}>
                        <ListItemIcon className={classes.listIcon}>
                            <PersonIcon className={classes.menuIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.textListHead}>
                            <div className={classes.fontMenu}>
                                Subscribers
                            </div>
                        </ListItemText>
                    </ListItem>
                    <Collapse in={openSubscribers} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.listItem}>
                            <ListItem onClick={() => clickedTab(4)} button className={activeTab === 4 ? classes.nestedActive : classes.nested}>
                                <ListItemIcon className={classes.listIcon}>
                                    <PersonOutlineOutlinedIcon className={classes.menuIcon} />
                                </ListItemIcon>
                                <ListItemText className={classes.textList}>
                                    <div className={classes.fontMenu}>
                                        Reg Subscribers
                                    </div>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </div>
            <div className={classes.bottomSection}>
                {openPopup &&
                    <Button className={classes.divLogout} onClick={() => onLogoutClicked()}>
                        Log out
                    </Button>
                }
                <Profile onClick={handleClickPopup}>
                    <div className={classes.fontMenu}>
                        {data.username}
                    </div>
                    <img src={DownArrowWhite} alt='arrow' className={classes.downArrow} />
                </Profile>
            </div>
        </div>
    )
}

const Profile = withStyles((theme) => ({
    root: {
        width: '80%',
        height: 40,
        color: theme.palette.getContrastText('#793ca8'),
        backgroundColor: '#793ca8',
        borderRadius: 40,
        '&:hover': {
            color: theme.palette.getContrastText('#652f8e'),
            backgroundColor: '#793ca8aa',
        },
        textTransform: 'none',
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: '#652f8e',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    topLogo: {
        width: '100%',
        height: 80,
        backgroundColor: '#5f258b',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20
    },
    imgLogo: {
        width: '80%',
        paddingRight: 10
    },
    bottomSection: {
        width: '100%',
        height: 80,
        backgroundColor: '#5f258b',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    topSection: {
        height: '100%'
    },
    textList: {
        color: 'white',
        marginLeft: 2,
        fontFamily: 'Poppins'
    },
    textListHead: {
        color: 'white',
        fontFamily: 'Poppins',
        marginLeft: 10,
    },
    listRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    nested: {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        width: 200,
        '& $menuIcon': {
            color: '#ffffff'
        },
        '&:hover': {
            backgroundColor: 'white',
            '& $textList': {
                color: '#652f8e',
            },
            '& $menuIcon': {
                color: '#652f8e'
            }
        },
    },
    nestedActive: {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        width: 200,
        backgroundColor: 'white',
        '& $textList': {
            color: '#652f8e',
        },
        '& $menuIcon': {
            color: '#652f8e'
        },
        '&:hover': {
            backgroundColor: 'white',
            '& $textList': {
                color: '#652f8e',
            }
        },
    },
    focusVisible: {},
    listItemHead: {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        width: 250,
        paddingLeft: 40,
        '& $menuIcon': {
            color: '#ffffff'
        },
        '&:hover': {
            backgroundColor: 'white',
            '& $textListHead': {
                color: '#652f8e',
                fontSize: 12
            },
            '& $menuIcon': {
                color: '#652f8e'
            },
        },
    },
    listItemHeadActive: {
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        width: 250,
        paddingLeft: 40,
        backgroundColor: 'white',
        '& $textListHead': {
            color: '#652f8e',
            fontSize: 12
        },
        '& $menuIcon': {
            color: '#652f8e'
        },
        '&:hover': {
            backgroundColor: 'white',
            '& $textListHead': {
                color: '#652f8e',
                fontSize: 12
            },
        },
    },
    divPopup: {
        position: 'absolute'
    },
    buttonLogout: {
        width: 100,
        height: 50,
        borderRadius: 50
    },
    divLogout: {
        position: 'absolute',
        backgroundColor: '#f3f3f3',
        marginBottom: 100,
        marginLeft: 100,
        width: 110,
        height: 50,
        borderRadius: 50,
        textTransform: 'none',
        '&:hover': {
            color: theme.palette.getContrastText('#f3f3f3'),
            backgroundColor: '#f3f3f3aa',
        },
        fontFamily: 'Poppins',
        fontSize: 12
    },
    downArrow: {
        height: 15,
        position: 'absolute',
        right: 20,
    },
    listIcon: {
        minWidth: 0
    },
    menuIcon: {
        height: 20,
    },
    fontMenu: {
        fontSize: 14
    }
}));

export default Sidebar
