import createDataContext from './createDataContext';
import api from '../api/api';

const PaymentReducer = (state, action) => {
    switch (action.type) {
        case 'get_offline_sales_payment':
            return { ...state, dataOfflineSalesPayment: action.payload, isLoading: false };
        case 'get_online_sales_payment':
            return { ...state, dataOnlineSalesPayment: action.payload, isLoading: false };
        case 'is_loading':
            return { ...state, isLoading: action.payload, errorMessage: '' };
        case 'add_error':
            return { ...state, errorMessage: action.payload, isLoading: false };
        case 'set_empty_array':
            return { ...state, dataOnlineSalesPayment: [] }
        case 'get_online_sales_qris_payment':
            return { ...state, dataOnlineSalesQRISPayment: action.payload, isLoading: false }
        default:
            return state;
    }
};

const getOfflineSalesPayment = dispatch => async () => {
    dispatch({ type: 'is_loading', payload: true });
    try {
        const response = await api.get('/admin/ShowSalesPayment');
        if (response.data.response === null)
            dispatch({ type: 'get_offline_sales_payment', payload: [] })
        else
            dispatch({ type: 'get_offline_sales_payment', payload: response.data.response })
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with get offline sales payment'
        })
    }
}

const getOnlineSalesPayment = dispatch => async () => {
    dispatch({ type: 'is_loading', payload: true });
    try {
        const response = await api.post('/admin/ShowOnlineSalesPayment');
        if (response.data.response === null)
            dispatch({ type: 'get_online_sales_payment', payload: [] })
        else
            dispatch({ type: 'get_online_sales_payment', payload: response.data.response })
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with get offline sales payment'
        })
    }
}

const getOnlineSalesQRISPayment = dispatch => async () => {
    dispatch({ type: 'is_loading', payload: true });
    try {
        const response = await api.post('/admin/ShowQris');
        if (response.data.response === null)
            dispatch({ type: 'get_online_sales_qris_payment', payload: [] })
        else
            dispatch({ type: 'get_online_sales_qris_payment', payload: response.data.response })
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with get offline sales payment'
        })
    }
}

const setEmptyArrayPayment = dispatch => () => {
    dispatch({ type: 'set_empty_array' });
}

export const { Provider, Context } = createDataContext(
    PaymentReducer,
    { getOfflineSalesPayment, getOnlineSalesPayment, setEmptyArrayPayment, getOnlineSalesQRISPayment },
    { dataOfflineSalesPayment: [], dataOnlineSalesPayment: [], dataOnlineSalesQRISPayment: [], errorMessage: '', isLoading: false }
)