import React, { useState, useContext, useEffect } from 'react';
import Logo from '../../assets/images/LogoPembukuanDigitalWText.png';
import RedIllustration from '../../assets/images/redbg.jpg';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress, Grid, Hidden, TextField, Select, MenuItem, Switch, FormControlLabel, FormHelperText, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { Context } from '../../context/AddMerchantContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dataKota from "../../kota&provinsi.json";

const ResetPassword = () => {
    const { state: { errorMessage, isLoading }, updateOutlet } = useContext(Context);
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [outletName, setOutletName] = useState('');
    const [businessCategory, setBusinessCategory] = useState('');
    const [openBusinessCategory, setOpenBusinessCategory] = useState('');
    const [outletAddress, setOutletAddress] = useState('');
    const [outletCityState, setOutletCityState] = useState('');
    const [onlineStoreName, setOnlineStoreName] = useState('');
    const [onlineStoreActive, setOnlineStoreActive] = useState(false);
    const [username, setUsername] = useState('');

    const [touchedOutletName, setTouchedOutletName] = useState(false);
    // const [touchedBusinessCategory, setTouchedBusinessCategory] = useState(false);
    const [touchedOutletAddress, setTouchedOutletAddress] = useState(false);
    // const [touchedOutletCity, setTouchedOutletCity] = useState(false);
    // const [touchedOutletState, setTouchedOutletState] = useState(false);
    const [touchedOnlineStoreName, setTouchedOnlineStoreName] = useState(false);
    const [touchedUsername, setTouchedUsername] = useState(false);

    const [errorUsername, setErrorUsername] = useState('');
    const [errorOutletName, setErrorOutletName] = useState('');
    const [errorBusinessCategory, setErrorBusinessCategory] = useState('');
    const [errorOutletAddress, setErrorOutletAddress] = useState('');
    const [errorOutletCityState, setErrorOutletCityState] = useState('');
    // const [errorOutletState, setErrorOutletState] = useState('');
    const [errorOnlineStoreName, setErrorOnlineStoreName] = useState('');

    useEffect(
        () => {
            document.title = 'Pembukuan Digital';
            var link = document.querySelector("link[rel~='icon']")
            link.href = "/LogoPD.png";
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const checkUsername = () => {
        setTouchedUsername(true);
        const listNumber = ['0811', '0812', '0813', '0821', '0822', '0823', '0851', '0852', '0853']
        const checkNumber = listNumber.includes(username.substring(0, 4))
        if (username === "") {
            setErrorUsername('Phone Outlet is required');
        } else if (!checkNumber) {
            setErrorUsername('Phone Outlet is not Telkomsel number');
        } else if (username.length < 10) {
            setErrorUsername('Phone Outlet must be at least 10 characters');
        } else if (username.length > 12) {
            setErrorUsername('Phone Outlet max 12 characters');
        } else {
            setErrorUsername('');
        }
    }

    const checkOutletName = () => {
        setTouchedOutletName(true);
        if (outletName === "") {
            setErrorOutletName('Nama Outlet harus diisi');
        } else {
            setErrorOutletName('');
        }
    }

    const checkOutletAddress = () => {
        setTouchedOutletAddress(true);
        if (outletAddress === "") {
            setErrorOutletAddress('Alamat Outlet harus diisi');
        } else {
            setErrorOutletAddress('');
        }
    }

    const checkBusinessCategory = () => {
        // setTouchedBusinessCategory(true);
        if (businessCategory === "") {
            setErrorBusinessCategory('Kategori Bisnis harus diisi');
        } else {
            setErrorBusinessCategory('');
        }
    }

    const checkOutletCityState = () => {
        // setTouchedBusinessCategory(true);
        if (outletCityState === "") {
            setErrorOutletCityState('Kota & Provinsi harus diisi');
        } else {
            setErrorOutletCityState('');
        }
    }

    const checkOnlineStoreName = () => {
        setTouchedOnlineStoreName(true);
        if (onlineStoreName === "") {
            setErrorOnlineStoreName('Nama Toko Online harus diisi');
        } else {
            setErrorOnlineStoreName('');
        }
    }

    const onResetPasswordClicked = () => {
        checkUsername();
        checkOutletName();
        checkBusinessCategory();
        checkOutletAddress();
        checkOutletCityState();
        if (onlineStoreActive)
            checkOnlineStoreName();
        if (errorUsername === '' && errorOutletName === '' && errorOutletAddress === '' && errorBusinessCategory === '' && errorOutletCityState === '' && errorOnlineStoreName === '') {
            const token = location.pathname.split('/')[2] || '';
            // console.log('nama_outlet: ' + outletName);
            // console.log('alamat: ' + outletAddress + '|' + outletCityState);
            // console.log('kategori_bisnis: ' + businessCategory);
            // console.log(onlineStoreActive ? 'is_online_store_active: Yes' : 'is_online_store_active: No');
            // console.log(onlineStoreName !== "" ? 'mini_website_url: https://orderin.id/' + onlineStoreName.trim().toLowerCase().replace(/ /g, "-") : `mini_website_url: ${""}`);
            updateOutlet({ token, user_id: username, nama_outlet: outletName, address: outletAddress + '|' + outletCityState, business_category: businessCategory, isActive: onlineStoreActive ? "Yes" : "No", mini_website_url: onlineStoreName !== "" ? "https://orderin.id/" + onlineStoreName.trim().toLowerCase().replace(/ /g, "-") : "", onSuccess: () => history.replace('/apps') })
        }
    }

    const handleClose = () => {
        setOpenBusinessCategory(false);
    };

    const handleOpen = () => {
        setOpenBusinessCategory(true);
    };

    const handleChange = (event) => {
        setBusinessCategory(event.target.value);
        setErrorBusinessCategory('');
    };

    const handleOnChangeCity = (event, newValue) => {
        console.log(newValue);
        const city = newValue.subdistrict_name + ', ' + newValue.city;
        const province = newValue.province;
        const id_kecamatan = newValue.subdistrict_id;
        const id_city = newValue.city_id;
        const id_provinsi = newValue.province_id;
        const kode_pos = newValue.postal_code;
        setOutletCityState(city + '|' + province + '|' + id_kecamatan + '|' + id_city + '|' + id_provinsi + '|' + kode_pos);
        setErrorOutletCityState('');
    }

    const handleOnlineStoreSwitch = () => {
        setOnlineStoreActive(!onlineStoreActive)
        setOnlineStoreName('');
        setErrorOnlineStoreName('');
        setTouchedOnlineStoreName(false);
    }

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={12} sm={12} md={6} className={classes.leftSide}>
                <form className={classes.form}>
                    <div className={classes.divLogo}>
                        <img className={classes.iconLogo} alt={"icon"} src={Logo} />
                    </div>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Phone Outlet
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            name="username"
                            type="number"
                            placeholder="Nomor Telepon"
                            onBlur={() => checkUsername()}
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                            error={touchedUsername && errorUsername !== ""}
                            helperText={touchedUsername && errorUsername}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Nama Outlet
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="outletName"
                            name="outletName"
                            onBlur={() => checkOutletName()}
                            value={outletName}
                            onChange={event => setOutletName(event.target.value)}
                            error={touchedOutletName && errorOutletName !== ""}
                            helperText={touchedOutletName && errorOutletName}
                        />
                    </FormControl>
                    <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                        Kategori Bisnis
                    </InputLabel>
                    <FormControl variant="outlined" className={classes.formControl} error={errorBusinessCategory !== ""}>
                        <div style={{ padding: 2 }} />
                        <Select
                            className={classes.selectField}
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={openBusinessCategory}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            value={businessCategory}
                            onChange={handleChange}>
                            <MenuItem value={"Bengkel"}>Bengkel</MenuItem>
                            <MenuItem value={"Restoran"}>Restoran</MenuItem>
                            <MenuItem value={"Cafe"}>Cafe</MenuItem>
                            <MenuItem value={"Toko Retail"}>Toko Retail</MenuItem>
                            <MenuItem value={"Cuci Mobil"}>Cuci Mobil</MenuItem>
                            <MenuItem value={"Fastfood"}>Fastfood</MenuItem>
                            <MenuItem value={"Food Truck"}>Food Truck</MenuItem>
                            <MenuItem value={"Jasa Katering"}>Jasa Katering</MenuItem>
                            <MenuItem value={"Jasa Reparasi"}>Jasa Reparasi</MenuItem>
                            <MenuItem value={"Laundry"}>Laundry</MenuItem>
                            <MenuItem value={"Makanan & Minuman"}>Makanan & Minuman</MenuItem>
                            <MenuItem value={"Rental"}>Rental</MenuItem>
                            <MenuItem value={"Salon & Spa"}>Salon & Spa</MenuItem>
                            <MenuItem value={"Toko Kelontong"}>Toko Kelontong</MenuItem>
                            <MenuItem value={"Toko Komputer"}>Toko Komputer</MenuItem>
                            <MenuItem value={"Handphone/Pulsa Outlet"}>Handphone/Pulsa Outlet</MenuItem>
                            <MenuItem value={"Toko Elektronik"}>Toko Elektronik</MenuItem>
                            <MenuItem value={"Toko Swalayan"}>Toko Swalayan</MenuItem>
                            <MenuItem value={"Toko dan usaha lainnya"}>Toko dan usaha lainnya</MenuItem>
                        </Select>
                        <FormHelperText>{errorBusinessCategory}</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Alamat Outlet
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="outletAddress"
                            name="outletAddress"
                            onBlur={() => checkOutletAddress()}
                            value={outletAddress}
                            onChange={event => setOutletAddress(event.target.value)}
                            error={touchedOutletAddress && errorOutletAddress !== ""}
                            helperText={touchedOutletAddress && errorOutletAddress}
                        />
                    </FormControl>
                    <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                        Kota & Provinsi
                    </InputLabel>
                    <FormControl variant="outlined" className={classes.formControl} error={errorOutletCityState !== ""}>
                        <div style={{ padding: 2 }} />
                        <Autocomplete
                            id="combo-box-demo"
                            options={dataKota}
                            onChange={(event, newValue) => handleOnChangeCity(event, newValue)}
                            getOptionLabel={(option) => option.subdistrict_name + ', ' + option.city + ', ' + option.province}
                            renderInput={(params) =>
                                <TextField {...params} variant="outlined" />
                            }
                        />
                        <FormHelperText>{errorOutletCityState}</FormHelperText>
                    </FormControl>
                    {/* <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                            Provinsi
                        </InputLabel>
                        <div style={{ padding: 2 }} />
                        <FieldInputLogin
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id="outletState"
                            name="outletState"
                            onBlur={() => checkOutletState()}
                            value={outletState}
                            onChange={event => setOutletState(event.target.value)}
                            error={touchedOutletState && errorOutletState !== ""}
                            helperText={touchedOutletState && errorOutletState}
                        />
                    </FormControl> */}
                    <FormControl className={classes.formControl}>
                        <FormControlLabel
                            control={<Switch color="primary" checked={onlineStoreActive} onChange={handleOnlineStoreSwitch} name="onlineStoreActive" />}
                            label="Aktifkan Toko Online"
                        />
                    </FormControl>
                    {onlineStoreActive &&
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="bootstrap-input" className={classes.labelInput}>
                                Nama Toko Online
                            </InputLabel>
                            <div style={{ padding: 2 }} />
                            <FieldInputLogin
                                variant="outlined"
                                size="small"
                                margin="normal"
                                required
                                fullWidth
                                id="onlineStoreName"
                                name="onlineStoreName"
                                onBlur={() => checkOnlineStoreName()}
                                value={onlineStoreName}
                                onChange={event => setOnlineStoreName(event.target.value)}
                                error={touchedOnlineStoreName && errorOnlineStoreName !== ""}
                                helperText={touchedOnlineStoreName && errorOnlineStoreName}
                            />
                        </FormControl>
                    }
                    {errorMessage.length > 0 &&
                        <div className={classes.divError}>
                            {errorMessage}
                        </div>
                    }
                    <div className={classes.buttonVertical}>
                        <div className={classes.divButtonPrimary}>
                            <ColorButton variant="contained" onClick={onResetPasswordClicked} disabled={isLoading}>
                                SAVE
                            </ColorButton>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>
                </form>
            </Grid>
            <Grid item xs={false} sm={false} md={6}>
                <Hidden smDown>
                    <img src={RedIllustration} alt={"illustration"} className={classes.imgRight} />
                </Hidden>
            </Grid>
        </Grid>
    )
}

const ColorButton = withStyles((theme) => ({
    root: {
        fontWeight: 'bold',
        color: theme.palette.getContrastText('#FF0025'),
        backgroundColor: '#FF0025',
        '&:hover': {
            backgroundColor: '#FF0000',
        },
        borderRadius: 50,
        width: '100%',
        height: 50
    },
}))(Button);

const FieldInputLogin = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#b9093e',
                fontFamily: 'TelkomselBatik',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        fontFamily: 'Poppins'
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form: {
        width: '60%', // Fix IE 11 issue.
    },
    divLogo: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20
    },
    iconLogo: {
        height: 80
    },
    formControl: {
        width: '100%',
        marginBottom: 10
    },
    formControlHorizontal: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'pink',
    },
    labelInput: {
        fontSize: 20,
        color: '#000',
    },
    buttonVertical: {
        display: 'flex',
        flexDirection: 'column'
    },
    divError: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        borderWidth: 1,
        borderColor: 'red',
        border: 'solid',
        color: 'red',
        marginBottom: 15
    },
    divButtonPrimary: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    buttonProgress: {
        color: '#652f8e',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    imgRight: {
        width: '100%',
        height: '100%'
    },
    selectField: {
        fontSize: 14,
        height: 40
    },
}));

export default ResetPassword
