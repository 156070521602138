import React, { useContext, useEffect, useState } from 'react';
import { Drawer, Hidden } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Sidebar from '../../components/Sidebar';
import { Context } from '../../context/SleepingContext';
import { Context as AuthContext } from '../../context/AuthContext';
import TableData from '../../components/TableData';
import ToggleMenu from '../../assets/icons/ToggleMenu.png';

const drawerWidth = 270;

const SleepingMerchant = () => {
    const classes = useStyles();
    const { state: { dataShowSleep, isLoading }, getShowSleep } = useContext(Context);
    const { state: { data } } = useContext(AuthContext);

    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(
        () => {
            getShowSleep();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <Sidebar />
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <Sidebar />
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.divMain}>
                    <div className={classes.divLabel}>
                        <Hidden mdUp >
                            <img onClick={handleDrawerToggle} src={ToggleMenu} alt='menu' className={classes.toggleMenu} />
                        </Hidden>
                        <label className={classes.title}>Sleeping Merchant List</label>
                    </div>
                    <TableData data={dataShowSleep} isLoading={isLoading} refCode={data.referral_code} />
                </div>
            </main>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        fontFamily: 'Poppins',
        display: 'flex',
    },
    divMain: {
        padding: 10
    },
    divLabel: {
        height: 30,
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: 'Poppins'
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    toggleMenu: {
        height: '100%',
        marginRight: 10
    }
}));

export default SleepingMerchant
