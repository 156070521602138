import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom';
import ButtonPrimary from '../../components/Button/ButtonPrimary';

const NotFound = () => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <div className={classes.root}>
            <Typography className={classes.codeError}>404</Typography>
            <Typography className={classes.title}>Oops . . .</Typography>
            <Typography className={classes.content}>The page does not exist or is unavailable</Typography>
            <div className={classes.divButton}>
                <ButtonPrimary text="Go back to home" onClick={() => history.push('/')} />
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    codeError: {
        fontSize: 150,
    },
    title: {
        fontSize: 50
    },
    content: {
        fontSize: 25
    },
    divButton: {
        marginTop: 50,
        width: 400
    }
}));

export default NotFound
