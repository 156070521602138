import createDataContext from './createDataContext';
import api from '../api/api';

const SleepingReducer = (state, action) => {
    switch (action.type) {
        case 'get_show_sleep':
            return { ...state, dataShowSleep: action.payload, isLoading: false };
        case 'get_already':
            return { ...state, dataAlready: action.payload, isLoading: false };
        case 'get_not_contact':
            return { ...state, dataNotContact: action.payload, isLoading: false };
        case 'is_loading':
            return { ...state, isLoading: action.payload, errorMessage: '' };
        case 'is_loading_feedback':
            return { ...state, isLoadingFeedback: action.payload, errorMessage: '' };
        case 'add_error':
            return { ...state, errorMessage: action.payload, isLoading: false, isLoadingFeedback: false };
        case 'add_error_feedback':
            return { ...state, errorMessageFeedback: action.payload, isLoading: false, isLoadingFeedback: false };
        case 'set_active_tab':
            return { ...state, activeTab: action.payload };
        case 'set_empty_array':
            return { ...state, dataShowSleep: [], dataAlready: [], dataNotContact: [] }
        case 'set_sleeping_filter_text':
            return { ...state, sleepingFilterText: action.payload }
        case 'set_already_filter_text':
            return { ...state, alreadyFilterText: action.payload }
        case 'set_not_contacted_filter_text':
            return { ...state, notContactedFilterText: action.payload }
        case 'set_showed_sleeping_data':
            return { ...state, showedSleepingData: action.payload }
        case 'set_showed_already_data':
            return { ...state, showedAlreadyData: action.payload }
        case 'set_showed_not_contacted_data':
            return { ...state, showedNotContactedData: action.payload }
        default:
            return state;
    }
};

const getShowSleep = dispatch => async () => {
    dispatch({ type: 'is_loading', payload: true });
    try {
        const response = await api.post('/admin/ShowSleep');
        if (response.data.response === null)
            dispatch({ type: 'get_show_sleep', payload: [] })
        else
            dispatch({ type: 'get_show_sleep', payload: response.data.response })
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with get show sleep'
        })
    }
}

const getAlready = dispatch => async () => {
    dispatch({ type: 'is_loading', payload: true });
    try {
        const response = await api.post('/admin/Already');
        if (response.data.response === null)
            dispatch({ type: 'get_already', payload: [] })
        else
            dispatch({ type: 'get_already', payload: response.data.response })
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with get already'
        })
    }
}

const getNotYetContact = dispatch => async () => {
    dispatch({ type: 'is_loading', payload: true });
    try {
        const response = await api.post('/admin/NotYetContact');
        if (response.data.response === null)
            dispatch({ type: 'get_not_contact', payload: [] })
        else
            dispatch({ type: 'get_not_contact', payload: response.data.response })
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with get not yet contacted'
        })
    }
}

const setActiveTab = dispatch => (activeTab) => {
    dispatch({ type: 'set_active_tab', payload: activeTab });
    localStorage.setItem('activeTab', activeTab);
}

const createFeedback = dispatch => async ({ payload, onSuccess }) => {
    dispatch({ type: 'is_loading_feedback', payload: true });
    try {
        const response = await api.post('/post/create', payload);
        if (response.data.status === 'Success') {
            dispatch({ type: 'is_loading_feedback', payload: false });
            onSuccess();
        }
    } catch (err) {
        dispatch({
            type: 'add_error_feedback',
            payload: 'Something went wrong with create feedback'
        })
    }
}

const updateFeedback = dispatch => async ({ id, payload, onSuccess }) => {
    dispatch({ type: 'is_loading_feedback', payload: true });
    try {
        const response = await api.put(`/post/${id}`, payload);
        if (response.data.status === 200) {
            dispatch({ type: 'is_loading_feedback', payload: false });
            onSuccess();
        }
    } catch (err) {
        dispatch({
            type: 'add_error_feedback',
            payload: 'Something went wrong with update feedback'
        })
    }
}

const setEmptyArraySleep = dispatch => () => {
    dispatch({ type: 'set_empty_array' });
}

const setShowedSleepingData = dispatch => (data) => {
    dispatch({ type: 'set_showed_sleeping_data', payload: data });
}

const setShowedAlreadyData = dispatch => (data) => {
    dispatch({ type: 'set_showed_already_data', payload: data });
}

const setShowedNotContactedData = dispatch => (data) => {
    dispatch({ type: 'set_showed_not_contacted_data', payload: data });
}

const setSleepingFilterText = dispatch => (text) => {
    dispatch({ type: 'set_sleeping_filter_text', payload: text });
}

const setAlreadyFilterText = dispatch => (text) => {
    dispatch({ type: 'set_already_filter_text', payload: text });
}

const setNotContactedFilterText = dispatch => (text) => {
    dispatch({ type: 'set_not_contacted_filter_text', payload: text });
}

export const { Provider, Context } = createDataContext(
    SleepingReducer,
    { getShowSleep, getAlready, getNotYetContact, setActiveTab, createFeedback, updateFeedback, setEmptyArraySleep, setSleepingFilterText, setAlreadyFilterText, setNotContactedFilterText, setShowedSleepingData, setShowedAlreadyData, setShowedNotContactedData },
    { dataShowSleep: [], dataAlready: [], dataNotContact: [], isLoading: false, activeTab: 0, errorMessage: '', errorMessageFeedback: '', isLoadingFeedback: false, sleepingFilterText: '', alreadyFilterText: '', notContactedFilterText: '', showedSleepingData: [], showedAlreadyData: [], showedNotContactedData: [] }
)