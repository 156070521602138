import React, { useContext, useState, useEffect } from 'react';
import { CircularProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Select, MenuItem, withStyles, Button } from '@material-ui/core';
import moment from 'moment';
import ContactedIcon from '../../assets/icons/ContactedIcon.png';
import ContactedIconIdle from '../../assets/icons/ContactedIconIdle.png';
import ContactedIconRed from '../../assets/icons/ContactedIconRed.png';
import 'moment/locale/id';
import { useHistory, useLocation } from 'react-router-dom';
import { Context } from '../../context/SleepingContext';

const TableData = ({ data = [], isLoading, refCode = "" }) => {

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const rowsPerPage = 20;
    const [dataLength, setDataLength] = useState(data.length);
    const history = useHistory();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const { state: { sleepingFilterText, alreadyFilterText, notContactedFilterText, showedSleepingData, showedAlreadyData, showedNotContactedData }, setSleepingFilterText, setAlreadyFilterText, setNotContactedFilterText, setShowedSleepingData, setShowedAlreadyData, setShowedNotContactedData } = useContext(Context);

    const [filter, setFilter] = useState('');
    const [choice, setChoice] = useState('referral_code');
    const [open, setOpen] = useState(false);
    const [showedData, setShowedData] = useState([]);
    const [isDate, setIsDate] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);
    const location = useLocation();

    const handleChange = (event) => {
        // const tempData = data.filter(x => x.referral_code.toLowerCase().includes(refCode.toLowerCase()));
        if (event.target.value === 'create_dtm' || event.target.value === 'register')
            setIsDate(true);
        else
            setIsDate(false);
        setChoice(event.target.value);
        // setFilter('');
        // setShowedData(data);
        // setDataLength(data.length);
    };

    const handleChangeFilterText = () => {
        // const tempData = data.filter(x => x.referral_code.toLowerCase().includes(refCode.toLowerCase()));
        if (location.pathname.includes('/sleeping')) {
            setSleepingFilterText(filter);
        } else if (location.pathname.includes('/already')) {
            setAlreadyFilterText(filter);
        } else if (location.pathname.includes('/notcontact')) {
            setNotContactedFilterText(filter);
        }
        const text = filter;
        var temp = [];
        // setFilter(text);
        if (choice === 'create_dtm') {
            temp = data.filter(x => x.create_dtm.includes(moment(text).format('YYYY-MM-DD')));
            setShowedData(temp);
            setDataLength(temp.length);
            if (location.pathname.includes('/sleeping')) {
                setShowedSleepingData(temp);
            } else if (location.pathname.includes('/already')) {
                setShowedAlreadyData(temp);
            } else if (location.pathname.includes('/notcontact')) {
                setShowedNotContactedData(temp);
            }
        } else if (choice === 'register') {
            temp = data.filter(x => x.register.includes(moment(text).format('YYYY-MM-DD')));
            setShowedData(temp);
            setDataLength(temp.length);
            if (location.pathname.includes('/sleeping')) {
                setShowedSleepingData(temp);
            } else if (location.pathname.includes('/already')) {
                setShowedAlreadyData(temp);
            } else if (location.pathname.includes('/notcontact')) {
                setShowedNotContactedData(temp);
            }
        } else if (choice === 'user_id') {
            if (text[0] === '0')
                temp = data.filter(x => x.user_id.includes(text) || x.user_id.includes('+62' + text.substring(1, text.length)));
            else
                temp = data.filter(x => x.user_id.includes(text));
            setShowedData(temp);
            setDataLength(temp.length);
            if (location.pathname.includes('/sleeping')) {
                setShowedSleepingData(temp);
            } else if (location.pathname.includes('/already')) {
                setShowedAlreadyData(temp);
            } else if (location.pathname.includes('/notcontact')) {
                setShowedNotContactedData(temp);
            }
        } else if (choice === 'merchant_owner') {
            temp = data.filter(x => x.toko_name_address.split('|')[0].toLowerCase().includes(text.toLowerCase()));
            setShowedData(temp);
            setDataLength(temp.length);
            if (location.pathname.includes('/sleeping')) {
                setShowedSleepingData(temp);
            } else if (location.pathname.includes('/already')) {
                setShowedAlreadyData(temp);
            } else if (location.pathname.includes('/notcontact')) {
                setShowedNotContactedData(temp);
            }
        } else if (choice === 'owner_name') {
            temp = data.filter(x => x.owner_name.toLowerCase().includes(text.toLowerCase()));
            setShowedData(temp);
            setDataLength(temp.length);
            if (location.pathname.includes('/sleeping')) {
                setShowedSleepingData(temp);
            } else if (location.pathname.includes('/already')) {
                setShowedAlreadyData(temp);
            } else if (location.pathname.includes('/notcontact')) {
                setShowedNotContactedData(temp);
            }
        } else if (choice === 'referral_code') {
            temp = data.filter(x => x.referral_code.toLowerCase().includes(text.toLowerCase()));
            setShowedData(temp);
            setDataLength(temp.length);
            if (location.pathname.includes('/sleeping')) {
                setShowedSleepingData(temp);
            } else if (location.pathname.includes('/already')) {
                setShowedAlreadyData(temp);
            } else if (location.pathname.includes('/notcontact')) {
                setShowedNotContactedData(temp);
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        localStorage.setItem('last_page', newPage);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(
        () => {
            const lastPage = localStorage.getItem('last_page') || 0;
            // setShowedData(data);
            // setDataLength(data.length);
            setPage(parseInt(lastPage));
            // const tempData = data.filter(x => x.referral_code.toLowerCase().includes(refCode.toLowerCase()));
            if (location.pathname.includes('/admin')) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
            if (location.pathname.includes('/sleeping')) {
                setShowedAlreadyData([]);
                setShowedNotContactedData([]);
                setAlreadyFilterText('');
                setNotContactedFilterText('');
                setFilter(sleepingFilterText);
                if (showedSleepingData.length !== 0) {
                    setShowedData(showedSleepingData);
                    setDataLength(showedSleepingData.length);
                } else {
                    setShowedData(data);
                    setDataLength(data.length);
                }
            } else if (location.pathname.includes('/already')) {
                setShowedSleepingData([]);
                setShowedNotContactedData([]);
                setSleepingFilterText('');
                setNotContactedFilterText('');
                setFilter(alreadyFilterText);
                if (showedAlreadyData.length !== 0) {
                    setShowedData(showedAlreadyData);
                    setDataLength(showedAlreadyData.length);
                } else {
                    setShowedData(data);
                    setDataLength(data.length);
                }
            } else if (location.pathname.includes('/notcontact')) {
                setShowedAlreadyData([]);
                setShowedSleepingData([]);
                setAlreadyFilterText('');
                setSleepingFilterText('');
                setFilter(notContactedFilterText);
                if (showedNotContactedData.length !== 0) {
                    setShowedData(showedNotContactedData);
                    setDataLength(showedNotContactedData.length);
                } else {
                    setShowedData(data);
                    setDataLength(data.length);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    return (
        <div>
            <div className={classes.divHeader}>
                <div className={classes.divFilter}>
                    Filter :
                    <TextField
                        className={classes.inputDate}
                        value={filter}
                        onChange={(event) => setFilter(event.target.value)}
                        type={isDate ? "date" : "text"}
                    />
                    <Select
                        className={classes.selectField}
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={choice}
                        onChange={handleChange}
                    >
                        <MenuItem value={"user_id"}>Phone Number</MenuItem>
                        <MenuItem value={"merchant_owner"}>Merchant Owner</MenuItem>
                        <MenuItem value={"owner_name"}>Owner Name</MenuItem>
                        <MenuItem value={"register"}>Register Date</MenuItem>
                        <MenuItem value={"create_dtm"}>Last Transaction</MenuItem>
                        <MenuItem value={"referral_code"}>Referral Code</MenuItem>
                    </Select>
                    {/* <div width={200} height={1} marginLeft={10}> */}
                    <ColorButton onClick={() => handleChangeFilterText()} >
                        Filter
                    </ColorButton>
                    {/* </div> */}
                </div>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={dataLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                // className={{ caption: classes.fontStandard }}
                />
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell className={classes.tableCellContacted}></TableCell>
                            <TableCell className={classes.tableCellHeader}>
                                <TableSortLabel
                                    className={classes.fontStandard}
                                    active={orderBy === "user_id"}
                                    direction={orderBy === "user_id" ? order : "asc"}
                                    onClick={() => handleRequestSort("user_id")}
                                >
                                    Phone Number
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">
                                <TableSortLabel
                                    className={classes.fontStandard}
                                    active={orderBy === "toko_name_address"}
                                    direction={orderBy === "toko_name_address" ? order : "asc"}
                                    onClick={() => handleRequestSort("toko_name_address")}
                                >
                                    Merchant Owner
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">
                                <TableSortLabel
                                    className={classes.fontStandard}
                                    active={orderBy === "owner_name"}
                                    direction={orderBy === "owner_name" ? order : "asc"}
                                    onClick={() => handleRequestSort("owner_name")}
                                >
                                    Owner Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">
                                <TableSortLabel
                                    className={classes.fontStandard}
                                    active={orderBy === "register"}
                                    direction={orderBy === "register" ? order : "asc"}
                                    onClick={() => handleRequestSort("register")}
                                >
                                    Register Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">
                                <TableSortLabel
                                    className={classes.fontStandard}
                                    active={orderBy === "create_dtm"}
                                    direction={orderBy === "create_dtm" ? order : "asc"}
                                    onClick={() => handleRequestSort("create_dtm")}
                                >
                                    Last Transaction
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.tableCellHeader} align="left">
                                <TableSortLabel
                                    className={classes.fontStandard}
                                    active={orderBy === "referral_code"}
                                    direction={orderBy === "referral_code" ? order : "asc"}
                                    onClick={() => handleRequestSort("referral_code")}
                                >
                                    Referral Code
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? stableSort(showedData, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : stableSort(showedData, getComparator(order, orderBy))
                        ).map((row) => (
                            <TableRow hover key={row.user_id} onClick={() => isAdmin ? history.push({ pathname: '/admin/detail', state: { data: row } }) : history.push({ pathname: '/detail', state: { data: row } })}>
                                <TableCell scope="row" className={classes.tableCellContacted} align="center">
                                    {row.feedback !== "" && row.boolean !== "1" &&
                                        <img src={ContactedIcon} alt='icon-contacted' className={classes.iconContact} />
                                    }
                                    {row.feedback === "" && row.boolean === "" && row.idpost === 0 &&
                                        <img src={ContactedIconIdle} alt='icon-contacted' className={classes.iconContact} />
                                    }
                                    {(row.feedback === "" && (row.boolean === "1" || row.idpost !== 0)) &&
                                        <img src={ContactedIconRed} alt='icon-contacted' className={classes.iconContact} />
                                    }
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    0{row.user_id.substring(3, row.user_id.length)}
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    {row.toko_name_address.split('|')[0]}
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    {row.owner_name}
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    {row.register !== "" ? moment(row.register.split('T')[0]).format('DD MMMM YYYY') : 'No Transaction'}
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    {row.create_dtm !== "" ? moment(row.create_dtm.split('T')[0]).format('DD MMMM YYYY') : 'No Transaction'}
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell}>
                                    {row.referral_code}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {!isLoading && data.length === 0 &&
                    <div className={classes.noData}>
                        No Data Available
                    </div>
                }
                {isLoading &&
                    <div className={classes.noData}>
                        <CircularProgress size={30} className={classes.buttonProgress} />
                    </div>
                }
            </TableContainer>
        </div>
    )
}

const ColorButton = withStyles((theme) => ({
    root: {
        fontWeight: 'bold',
        color: theme.palette.getContrastText('#652f8e'),
        backgroundColor: '#652f8e',
        '&:hover': {
            backgroundColor: '#884bb7',
        },
        borderRadius: 50,
        width: 80,
        height: 40,
        marginLeft: 10
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    tableHead: {
        backgroundColor: '#f3f3f3',
    },
    table: {
        borderWidth: 1,
        borderColor: '#8898a8'
    },
    iconContact: {
        height: 20,
        aspectRatio: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableCellContacted: {
        padding: 8,
        width: '3%',
    },
    tableCell: {
        padding: 8,
        width: '15%',
        fontFamily: 'Poppins',
        fontSize: 12
    },
    tableCellHeader: {
        padding: 8,
        fontWeight: 'bold',
        width: '15%',
        fontFamily: 'Poppins'
    },
    noData: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        fontFamily: 'Poppins',
        fontSize: 12
    },
    buttonProgress: {
        color: '#652f8e',
    },
    fontStandard: {
        fontSize: 12
    },
    divHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        marginBottom: 10,
        alignItems: 'center',
        height: 60
    },
    inputDate: {
        marginRight: 10,
        marginLeft: 10,
    },
    divFilter: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14
    },
    selectField: {
        fontSize: 14
    },
}));

export default TableData
