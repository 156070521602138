import React, { Fragment, useState, useEffect, useContext } from 'react';
import { CircularProgress, makeStyles, Select, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button, withStyles, TableSortLabel, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/id';
import { Context } from '../../context/SubscriberContext';

import { Line } from 'react-chartjs-2';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TableSubscribers = ({ data = [], isLoading, refCode = "" }) => {

    const { state: { subscribersFilterText, showedSubscribersData }, setSubscribersFilterText, setShowedSubscribersData } = useContext(Context);

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const rowsPerPage = 20;
    const [dataLength, setDataLength] = useState(data.length);
    const [active, setActive] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [chartData, setChartData] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

    const [filter, setFilter] = useState('');
    const [choice, setChoice] = useState('referral_code');
    const [open, setOpen] = useState(false);
    const [showedData, setShowedData] = useState([]);
    const [isDate, setIsDate] = useState(false);

    const handleChange = (event) => {
        // const tempData = data.filter(x => x.referral_code.toLowerCase().includes(refCode.toLowerCase()));
        if (event.target.value === 'register' || event.target.value === 'create_dtm')
            setIsDate(true);
        else
            setIsDate(false);
        setChoice(event.target.value);
        // setFilter('');
        // setShowedData(data);
        // setDataLength(data.length);
    };

    const handleChangeFilterText = () => {
        // const tempData = data.filter(x => x.referral_code.toLowerCase().includes(refCode.toLowerCase()));
        setSubscribersFilterText(filter);
        const text = filter;
        var temp = [];
        setFilter(text);
        if (choice === 'register') {
            temp = data.filter(x => x.register.includes(moment(text).format('YYYY-MM-DD')));
            setShowedData(temp);
            setDataLength(temp.length);
            setShowedSubscribersData(temp);
        } else if (choice === 'create_dtm') {
            temp = data.filter(x => x.create_dtm.includes(moment(text).format('YYYY-MM-DD')));
            setShowedData(temp);
            setDataLength(temp.length);
            setShowedSubscribersData(temp);
        } else if (choice === 'user_id') {
            if (text[0] === '0')
                temp = data.filter(x => x.user_id.includes(text) || x.user_id.includes('+62' + text.substring(1, text.length)));
            else
                temp = data.filter(x => x.user_id.includes(text));
            setShowedData(temp);
            setDataLength(temp.length);
            setShowedSubscribersData(temp);
        } else if (choice === 'email') {
            temp = data.filter(x => x.email.toLowerCase().includes(text.toLowerCase()));
            setShowedData(temp);
            setDataLength(temp.length);
            setShowedSubscribersData(temp);
        } else if (choice === 'owner_name') {
            temp = data.filter(x => x.owner_name.toLowerCase().includes(text.toLowerCase()));
            setShowedData(temp);
            setDataLength(temp.length);
            setShowedSubscribersData(temp);
        } else if (choice === 'referral_code') {
            temp = data.filter(x => x.referral_code.toLowerCase().includes(text.toLowerCase()));
            setShowedData(temp);
            setDataLength(temp.length);
            setShowedSubscribersData(temp);
        } else if (choice === 'kota') {
            temp = data.filter(x => x.kota.toLowerCase().includes(text.toLowerCase()));
            setShowedData(temp);
            setDataLength(temp.length);
            setShowedSubscribersData(temp);
        } else if (choice === 'provinsi') {
            temp = data.filter(x => x.provinsi.toLowerCase().includes(text.toLowerCase()));
            setShowedData(temp);
            setDataLength(temp.length);
            setShowedSubscribersData(temp);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(
        () => {
            getDataForChart();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, endDate, startDate]
    );

    useEffect(
        () => {
            // setShowedData(data);
            // setDataLength(data.length);
            // console.log(refCode);
            // const tempData = data.filter(x => x.referral_code.toLowerCase().includes(refCode.toLowerCase()));
            // var data = [];
            for (let i = 0; i < data.length; i++) {
                const tempKota = data[i].toko_name_address !== '' && data[i].toko_name_address !== undefined ? data[i].toko_name_address.split('|')[2] : '';
                const kota = tempKota !== undefined && tempKota !== '' ? tempKota.split(', ')[0] : '';
                const provinsi = data[i].toko_name_address !== '' && data[i].toko_name_address !== undefined ? data[i].toko_name_address.split('|')[3] : '';
                data[i].kota = kota;
                data[i].provinsi = provinsi;
            }
            if (subscribersFilterText !== '')
                setFilter(subscribersFilterText);
            if (showedSubscribersData.length !== 0) {
                setShowedData(showedSubscribersData);
                setDataLength(showedSubscribersData.length);
            } else {
                setShowedData(data);
                setDataLength(data.length);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const getDataForChart = () => {
        const different = moment(endDate).diff(moment(startDate), 'days');
        var labels = [];
        var dataTemp = [];
        var i;
        for (i = 0; i <= different; i++) {
            const dateFilter = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
            const date = moment(dateFilter).format('DD MMM');
            // const tempData = data.filter(x => x.referral_code.toLowerCase().includes(refCode.toLowerCase()));
            const user = data.filter(x => x.create_dtm.includes(dateFilter)).length;
            labels.push(date);
            dataTemp.push(user);
        }
        const datasets = [{
            label: `Jumlah user yang terdaftar`,
            data: dataTemp,
            fill: false,
            backgroundColor: 'rgb(101, 47, 142)',
            borderColor: 'rgba(101, 37, 142, 0.2)',
        }];
        setChartData({ labels, datasets });
    }

    const ColorButton = withStyles((theme) => ({
        root: {
            fontSize: 12,
            fontWeight: 'bold',
            color: theme.palette.getContrastText('#652f8e'),
            backgroundColor: '#652f8e',
            '&:hover': {
                backgroundColor: '#884bb7',
            },
            borderRadius: 50,
            width: 100,
            height: 40,
            padding: 20,
            marginRight: 10
        },
    }))(Button);

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    ticks: {
                        fontSize: 10
                    }
                }
            ]
        },
    }

    const Download = () => {
        const dateNow = moment().format('DD_MMM_YYYY_hh_mm_ss');
        return (
            <ExcelFile element={<ColorButton>Excel</ColorButton>} filename={`Data Subscribers_${dateNow}`}>
                <ExcelSheet data={stableSort(showedData, getComparator(order, orderBy))} name="Subscribers">
                    <ExcelColumn label="Register Date" value={(col) => moment(col.register.split('T')[0]).format('DD MMMM YYYY')} />
                    <ExcelColumn label="User Id" value={(col) => `0${col.user_id.substring(3, col.user_id.length)}`} />
                    <ExcelColumn label="Email" value="email" />
                    <ExcelColumn label="Owner Name" value="owner_name" />
                    <ExcelColumn label="Merchant Name" value={(col) => col.toko_name_address.split('|')[0]} />
                    <ExcelColumn label="Address" value={(col) => col.toko_name_address.split('|')[1]} />
                    <ExcelColumn label="Kota" value="kota" />
                    <ExcelColumn label="Provinsi" value="provinsi" />
                    <ExcelColumn label="Last Transaction" value={(col) => col.create_dtm !== '' ? moment(col.create_dtm.split('T')[0]).format('DD MMMM YYYY') : 'No Transaction'} />
                    <ExcelColumn label="Referral Code" value="referral_code" />
                </ExcelSheet>
            </ExcelFile>
        )
    }

    return (
        <div>
            <div className={classes.divHeader}>
                <div className={classes.divButtonMenu}>
                    {active === 0 &&
                        <Download />
                    }
                    <ColorButton onClick={() => setActive(0)}>Table</ColorButton>
                    <ColorButton onClick={() => setActive(1)}>Graph</ColorButton>
                    {active === 1 &&
                        <div className={classes.divFilter}>
                            Start Date:
                            <TextField
                                className={classes.inputDate}
                                id="date-picker-dialog"
                                type="date"
                                value={startDate}
                                onChange={(event) => setStartDate(event.target.value)}
                            />
                            End Date:
                            <TextField
                                className={classes.inputDate}
                                id="date-picker-dialog"
                                type="date"
                                value={endDate}
                                onChange={(event) => setEndDate(event.target.value)}
                            />
                        </div>
                    }
                    {active === 0 &&
                        <div className={classes.divFilter}>
                            Filter :
                            <TextField
                                className={classes.inputDate}
                                value={filter}
                                onChange={(event) => setFilter(event.target.value)}
                                type={isDate ? "date" : "text"}
                            />
                            <Select
                                className={classes.selectField}
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                open={open}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                value={choice}
                                onChange={handleChange}
                            >
                                <MenuItem value={"register"}>Register Date</MenuItem>
                                <MenuItem value={"user_id"}>User Id</MenuItem>
                                <MenuItem value={"email"}>Email</MenuItem>
                                <MenuItem value={"owner_name"}>Owner Name</MenuItem>
                                <MenuItem value={"referral_code"}>Referral Code</MenuItem>
                                <MenuItem value={"kota"}>Kota</MenuItem>
                                <MenuItem value={"provinsi"}>Provinsi</MenuItem>
                                <MenuItem value={"create_dtm"}>Last Transaction</MenuItem>
                            </Select>
                            <ColorButton onClick={() => handleChangeFilterText()}>Filter</ColorButton>
                        </div>
                    }
                </div>
                {active === 0 &&
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[]}
                        count={dataLength}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                }
            </div>
            {active === 0 ?
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell className={classes.tableCellHeader}>
                                    <TableSortLabel
                                        className={classes.fontStandard}
                                        active={orderBy === "register"}
                                        direction={orderBy === "register" ? order : "asc"}
                                        onClick={() => handleRequestSort("register")}
                                    >
                                        Register Date
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">
                                    <TableSortLabel
                                        className={classes.fontStandard}
                                        active={orderBy === "user_id"}
                                        direction={orderBy === "user_id" ? order : "asc"}
                                        onClick={() => handleRequestSort("user_id")}
                                    >
                                        User ID
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">
                                    <TableSortLabel
                                        className={classes.fontStandard}
                                        active={orderBy === "email"}
                                        direction={orderBy === "email" ? order : "asc"}
                                        onClick={() => handleRequestSort("email")}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">
                                    <TableSortLabel
                                        className={classes.fontStandard}
                                        active={orderBy === "owner_name"}
                                        direction={orderBy === "owner_name" ? order : "asc"}
                                        onClick={() => handleRequestSort("owner_name")}
                                    >
                                        Owner Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">
                                    <TableSortLabel
                                        className={classes.fontStandard}
                                        active={orderBy === "referral_code"}
                                        direction={orderBy === "referral_code" ? order : "asc"}
                                        onClick={() => handleRequestSort("referral_code")}
                                    >
                                        Referral Code
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">
                                    <TableSortLabel
                                        className={classes.fontStandard}
                                        active={orderBy === "kota"}
                                        direction={orderBy === "kota" ? order : "asc"}
                                        onClick={() => handleRequestSort("kota")}
                                    >
                                        Kota
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">
                                    <TableSortLabel
                                        className={classes.fontStandard}
                                        active={orderBy === "provinsi"}
                                        direction={orderBy === "provinsi" ? order : "asc"}
                                        onClick={() => handleRequestSort("provinsi")}
                                    >
                                        Provinsi
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} align="left">
                                    <TableSortLabel
                                        className={classes.fontStandard}
                                        active={orderBy === "create_dtm"}
                                        direction={orderBy === "create_dtm" ? order : "asc"}
                                        onClick={() => handleRequestSort("create_dtm")}
                                    >
                                        Last Transaction
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? stableSort(showedData, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : stableSort(showedData, getComparator(order, orderBy))
                            ).map((row, index) => (
                                <Row key={index} row={row} />
                            )
                            )}
                        </TableBody>
                    </Table>
                    {!isLoading && data.length === 0 &&
                        <div className={classes.noData}>
                            No Data Available
                        </div>
                    }
                    {isLoading &&
                        <div className={classes.noData}>
                            <CircularProgress size={30} className={classes.buttonProgress} />
                        </div>
                    }
                </TableContainer>
                :
                <Paper>
                    <Line data={chartData} options={options} />
                </Paper>
            }
        </div>
    )
}

const Row = ({ row }) => {
    const history = useHistory();
    const classes = useStyles();
    return (
        <Fragment>
            <TableRow hover onClick={() => history.push({ pathname: '/detailSubscriber', state: { data: row } })}>
                <TableCell align="left" className={classes.tableCell}>
                    {row.register !== '' ? moment(row.register.split('T')[0]).format('DD MMMM YYYY') : ''}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    0{row.user_id.substring(3, row.user_id.length)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.email}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.owner_name}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.referral_code}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.kota}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.provinsi}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {row.create_dtm !== '' ? moment(row.create_dtm.split('T')[0]).format('DD MMMM YYYY') : 'No Transaction'}
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    tableHead: {
        backgroundColor: '#f3f3f3',
    },
    table: {
        borderWidth: 1,
        borderColor: '#8898a8'
    },
    iconContact: {
        height: 20,
        aspectRatio: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableCellContacted: {
        padding: 8,
        width: '3%',
    },
    tableCell: {
        padding: 8,
        width: '12%',
        fontFamily: 'Poppins',
        fontSize: 12
    },
    tableCellHeader: {
        padding: 8,
        fontWeight: 'bold',
        width: '12%',
        fontFamily: 'Poppins',
        fontSize: 12
    },
    noData: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        fontFamily: 'Poppins',
        fontSize: 12
    },
    buttonProgress: {
        color: '#652f8e',
    },
    rowCustomerDetail: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: '#d3d3d3',
        border: 'solid'
    },
    rowTitle: {
        fontWeight: 'bold',
        width: 150,
        paddingTop: 5,
        paddingBottom: 5
    },
    divHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        marginBottom: 10,
        alignItems: 'center',
        height: 60
    },
    divButtonMenu: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputDate: {
        marginRight: 10,
        marginLeft: 10,
    },
    dataGrid: {
        height: 700
    },
    divFilter: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14
    },
    selectField: {
        fontSize: 14
    },
    fontStandard: {
        fontSize: 12
    }
}));

export default TableSubscribers
