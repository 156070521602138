import createDataContext from './createDataContext';
import api from '../api/api';

const SubscriberReducer = (state, action) => {
    switch (action.type) {
        case 'get_subscribers':
            return { ...state, dataSubscribers: action.payload, isLoading: false };
        case 'is_loading':
            return { ...state, isLoading: action.payload, errorMessage: '' };
        case 'add_error':
            return { ...state, errorMessage: action.payload, isLoading: false };
        case 'set_empty_array':
            return { ...state, dataSubscribers: [] }
        case 'set_subscribers_filter_text':
            return { ...state, subscribersFilterText: action.payload }
        case 'set_showed_subscribers_data':
            return { ...state, showedSubscribersData: action.payload }
        default:
            return state;
    }
};

const getAllSubscribers = dispatch => async () => {
    dispatch({ type: 'is_loading', payload: true });
    try {
        const response = await api.post('/admin/GetAllSubcribers');
        if (response.data.response === null)
            dispatch({ type: 'get_subscribers', payload: [] })
        else
            dispatch({ type: 'get_subscribers', payload: response.data.response })
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with get subscribers'
        })
    }
}

const setEmptyArraySub = dispatch => () => {
    dispatch({ type: 'set_empty_array' });
}

const setShowedSubscribersData = dispatch => (data) => {
    dispatch({ type: 'set_showed_subscribers_data', payload: data });
}

const setSubscribersFilterText = dispatch => (text) => {
    dispatch({ type: 'set_subscribers_filter_text', payload: text });
}

export const { Provider, Context } = createDataContext(
    SubscriberReducer,
    { getAllSubscribers, setEmptyArraySub, setShowedSubscribersData, setSubscribersFilterText },
    { dataSubscribers: [], errorMessage: '', isLoading: false, subscribersFilterText: '', showedSubscribersData: [] }
)