import React, { useEffect, useState } from 'react';
import { Drawer, Grid, Hidden, Paper, } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Sidebar from '../../components/Sidebar';
import ToggleMenu from '../../assets/icons/ToggleMenu.png';
import NoImage from '../../assets/images/noImage.png';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/id';

const drawerWidth = 270;

const DetailSubscriber = ({ location }) => {
    const classes = useStyles();

    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const { data } = location.state || [];

    useEffect(
        () => {
            if (data !== undefined) {

            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    if (location.state === undefined) {
        return (<Redirect to={{ pathname: '/subscribers' }} />)
    }

    return (
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <Sidebar />
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <Sidebar />
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.divMain}>
                    <div className={classes.divLabel}>
                        <Hidden mdUp >
                            <img onClick={handleDrawerToggle} src={ToggleMenu} alt='menu' className={classes.toggleMenu} />
                        </Hidden>
                        <label className={classes.title}>Subscriber Profile</label>
                    </div>
                    <Grid container component="main" spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper className={classes.paperImg}>
                                <img src={data.images ? data.images[0] : NoImage} alt='merchantPhoto' className={classes.merchantPhoto} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Paper className={classes.paperItem}>
                                <div className={classes.contentPaper}>
                                    <label className={classes.labelTitle}>Phone Number</label>
                                    <label className={classes.labelContent}>0{data.user_id.substring(3, data.user_id.length)}</label>
                                </div>
                            </Paper>
                            <Paper className={classes.paperItem}>
                                <div className={classes.contentPaper}>
                                    <label className={classes.labelTitle}>Merchant Name</label>
                                    <label className={classes.labelContent}>{data.toko_name_address !== "" ? data.toko_name_address.substring(0, data.toko_name_address.indexOf('|')) : "No Name"}</label>
                                </div>
                            </Paper>
                            <Paper className={classes.paperItem}>
                                <div className={classes.contentPaper}>
                                    <label className={classes.labelTitle}>Owner Name</label>
                                    <label className={classes.labelContent}>{data.owner_name}</label>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5}>
                            <Paper className={classes.paperItemAddress}>
                                <div className={classes.contentPaper}>
                                    <label className={classes.labelTitle}>Address</label>
                                    {data.toko_name_address !== "" && data.toko_name_address.split('|')[1] !== "undefined" && data.toko_name_address.split('|')[1] !== "" ?
                                        <label className={classes.labelContent}>{data.toko_name_address.split('|')[2] === "undefined" || data.toko_name_address.split('|')[3] === "undefined" || data.toko_name_address.split('|')[2] === "" || data.toko_name_address.split('|')[3] === "" ? data.toko_name_address.split('|')[1] : data.toko_name_address.split('|')[1] + ', ' + data.toko_name_address.split('|')[2] + ', ' + data.toko_name_address.split('|')[3]}</label>
                                        :
                                        <label className={classes.labelContent}>No Address</label>
                                    }
                                </div>
                            </Paper>
                            <Hidden only="sm">
                                <Paper className={classes.paperItem}>
                                    <div className={classes.contentPaper}>
                                        <label className={classes.labelTitle}>Last Transaction</label>
                                        <label className={classes.labelContent}>{data.create_dtm !== "" ? moment(data.create_dtm).format('DD MMMM YYYY HH:mm:ss') : "No Transaction"}</label>
                                    </div>
                                </Paper>
                            </Hidden>
                        </Grid>
                        <Hidden xsDown mdUp>
                            <Grid item sm={6}>
                                <Paper className={classes.paperItem}>
                                    <div className={classes.contentPaper}>
                                        <label className={classes.labelTitle}>Last Transaction</label>
                                        <label className={classes.labelContent}>{data.create_dtm !== "" ? moment(data.create_dtm).format('DD MMMM YYYY HH:mm:ss') : "No Transaction"}</label>
                                    </div>
                                </Paper>
                                <Paper className={classes.paperItem}>
                                    <div className={classes.contentPaper}>
                                        <label className={classes.labelTitle}>Date Register</label>
                                        <label className={classes.labelContent}>{data.tanggal !== "" ? moment(data.register).format('DD MMMM YYYY HH:mm:ss') : "No Transaction"}</label>
                                    </div>
                                </Paper>

                            </Grid>
                            <Grid item sm={12}>
                                <Paper className={classes.paperItem}>
                                    <div className={classes.contentPaperCenter}>
                                        <label className={classes.labelContent}>{data.business_category}</label>
                                    </div>
                                </Paper>
                            </Grid>
                        </Hidden>
                    </Grid>

                    <Grid container component="main" spacing={1}>
                        <Hidden smDown>
                            <Grid item xs={12} md={3}>
                                <Paper className={classes.paperItem}>
                                    <div className={classes.contentPaperCenter}>
                                        <label className={classes.labelContent}>{data.business_category}</label>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={0} md={4} />
                            <Grid item xs={12} md={5}>
                                <Paper className={classes.paperItem}>
                                    <div className={classes.contentPaper}>
                                        <label className={classes.labelTitle}>Date Register</label>
                                        <label className={classes.labelContent}>{data.tanggal !== "" ? moment(data.register).format('DD MMMM YYYY HH:mm:ss') : "No Transaction"}</label>
                                    </div>
                                </Paper>
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid item xs={12}>
                                <Paper className={classes.paperItem}>
                                    <div className={classes.contentPaper}>
                                        <label className={classes.labelTitle}>Date Register</label>
                                        <label className={classes.labelContent}>{data.tanggal !== "" ? moment(data.register).format('DD MMMM YYYY HH:mm:ss') : "No Transaction"}</label>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paperItem}>
                                    <div className={classes.contentPaperCenter}>
                                        <label className={classes.labelContent}>{data.business_category}</label>
                                    </div>
                                </Paper>
                            </Grid>
                        </Hidden>
                    </Grid>
                </div>
            </main>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        fontFamily: 'Poppins',
        display: 'flex',
    },
    divMain: {
        padding: 10
    },
    divLabel: {
        height: 30,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: 'Poppins',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    toggleMenu: {
        height: '100%',
        marginRight: 10
    },
    paperItem: {
        backgroundColor: '#f8f8f8',
        marginBottom: 10,
        height: 40,
        padding: 5
    },
    paperItemSmall: {
        backgroundColor: '#f8f8f8',
        marginBottom: 10,
        height: 40,
        padding: 1
    },
    paperItemContacted: {
        backgroundColor: '#652f8e',
        marginBottom: 20,
        height: 40,
        padding: 1,
        width: 300,
        borderRadius: 40
    },
    paperItemAddress: {
        backgroundColor: '#f8f8f8',
        marginBottom: 10,
        height: 100,
        padding: 5
    },
    merchantPhoto: {
        width: '100%',
        height: 170,
    },
    paperImg: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10
    },
    contentPaper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    labelTitle: {
        fontFamily: 'Poppins',
        fontSize: 13,
        color: '#616161'
    },
    labelContent: {
        fontFamily: 'Poppins',
        fontSize: 15,
        fontWeight: 'bold',
        color: '#000000',
    },
    labelContentContacted: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#FFFFFF',
    },
    contentPaperCenter: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    divRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    iconCheck: {
        width: 20,
        aspectRatio: 1,
        marginRight: 5
    },
    divFeedback: {
        width: '100%',
        borderWidth: 1,
        border: 'solid',
        borderRadius: 15,
        marginBottom: 20
    },
    divLabelFeedback: {
        padding: 10,
        borderBottomWidth: 1,
        borderWidth: 0,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: '#f8f8f8',
        border: 'solid',
    },
    labelFeedback: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000000'
    },
    divInputFeedback: {
        padding: 10
    },
    inputFeedback: {
        width: '100%',
    },
    bottomSection: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    divButton: {
        width: 125,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    helperFeedback: {
        color: '#787878'
    },
    labelError: {
        width: 150,
        textAlign: 'center',
        marginBottom: 10,
        color: 'red'
    },
    buttonProgress: {
        color: '#652f8e',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default DetailSubscriber