import createDataContext from './createDataContext';
import axios from 'axios';


const AddMerchantReducer = (state, action) => {
    switch (action.type) {
        case 'get_list_kota':
            return { ...state, dataKota: action.payload, isLoading: false };
        case 'is_loading':
            return { ...state, isLoading: action.payload, errorMessage: '' };
        case 'add_error':
            return { ...state, errorMessage: action.payload, isLoading: false };
        default:
            return state;
    }
};

const getKota = dispatch => async () => {
    try {
        // const response = await resetPassword.post('/v1/updatePassword', { token, password });
        const response = await axios.create({ baseURL: 'https://artaka-api.com/api/subdistrics' }).post('/show', { kecamatan: "xxx" });
        if (response.status === 200) {
            dispatch({ type: 'get_list_kota', payload: response.data });
        }
    } catch (err) {
        dispatch({
            type: 'add_error',
            payload: 'Something went wrong with get Kota'
        })
    }
}

const updateOutlet = dispatch => async ({ token, user_id, nama_outlet, address, business_category, mini_website_url, isActive, onSuccess }) => {
    dispatch({ type: 'is_loading', payload: true });
    if (token === '') {
        dispatch({ type: 'add_error', payload: "Token not available" });
    } else {
        try {
            // const response = await resetPassword.post('/v1/updatePassword', { token, password });
            const options = { headers: { 'Authorization': `Bearer ${token}` } };
            const response = await axios.create({ baseURL: 'https://artaka999.com/api' }).post('/merchant/outlet', { user_id, nama_outlet, address, business_category, mini_website_url, isActive }, options);
            if (response.data.status.toLowerCase() === "failed") {
                dispatch({ type: 'add_error', payload: response.data.error });
            } else if (response.data.status.toLowerCase() === "success") {
                onSuccess();
            }
        } catch (err) {
            dispatch({
                type: 'add_error',
                payload: 'Something went wrong with update outlet'
            })
        }
    }
}

export const { Provider, Context } = createDataContext(
    AddMerchantReducer,
    { getKota, updateOutlet },
    { dataKota: [], errorMessage: '', isLoading: false }
)
