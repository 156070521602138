import React, { useContext, useEffect } from 'react'
import Login from './pages/Login'
import Register from './pages/Register'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import SleepingMerchant from './pages/SleepingMerchant';
import AlreadyContacted from './pages/AlreadyContacted';
import NotYetContacted from './pages/NotYetContacted';
import DetailMerchant from './pages/DetailMerchant';
import OnlineSales from './pages/OnlineSales';
import OnlineSalesQRIS from './pages/OnlineSalesQRIS';
import { Context } from './context/AuthContext';
import NotFound from './pages/NotFound';
import Subscribers from './pages/Subscribers';
import DetailSubscriber from './pages/DetailSubscriber';
import ResetPassword from './pages/ResetPassword';
import RegisterMerchant from './pages/RegisterMerchant';

const App = () => {

    const { tryLocalLogin } = useContext(Context);

    useEffect(
        () => {
            tryLocalLogin();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const AuthRoute = ({ component: Component, ...rest }) => {
        return (
            <Route {...rest}
                render={props => {
                    const token = localStorage.getItem('token');
                    if (token !== null) {
                        return (
                            // rest.path.includes('/admin') ?
                            //     <Redirect to={{ pathname: "/admin/sleeping" }} />
                            //     :
                            <Redirect to={{ pathname: "/sleeping" }} />
                        )
                    } else {
                        return <Component {...props} />
                    }
                }}
            />
        )
    }

    const ProtectedRoute = ({ component: Component, ...rest }) => {
        return (
            <Route exact {...rest}
                render={props => {
                    const token = localStorage.getItem('token');
                    if (token === null) {
                        return (
                            // rest.path.includes('/admin') ?
                            //     <Redirect to={{ pathname: "/admin" }} />
                            //     :
                            <Redirect to={{ pathname: "/" }} />
                        )
                    } else {
                        return <Component {...props} />
                    }
                }}
            />
        )
    }

    const authRoutes = [
        {
            name: "Login",
            component: Login,
            exact: true,
            path: "/"
        },
        {
            name: "Register",
            component: Register,
            exact: true,
            path: "/register"
        },
        // {
        //     name: "AdminLogin",
        //     component: Login,
        //     exact: true,
        //     path: "/admin"
        // },
        {
            name: "AdminRegister",
            component: Register,
            exact: true,
            path: "/admin/register"
        }
    ];

    const appRoutes = [
        {
            name: "NotContactedYet",
            component: NotYetContacted,
            exact: true,
            path: "/notcontact"
        },
        {
            name: "AlreadyContacted",
            component: AlreadyContacted,
            exact: true,
            path: "/already"
        },
        {
            name: "DetailMerchant",
            component: DetailMerchant,
            exact: true,
            path: "/detail"
        },
        {
            name: "Dashboard",
            component: SleepingMerchant,
            exact: true,
            path: "/sleeping"
        },
        {
            name: "OnlineSales",
            component: OnlineSales,
            exact: true,
            path: "/onlinesales"
        },
        {
            name: "OnlineSalesQRIS",
            component: OnlineSalesQRIS,
            exact: true,
            path: "/onlinesalesqris"
        },
        {
            name: "Subscribers",
            component: Subscribers,
            exact: true,
            path: "/subscribers"
        },
        {
            name: "DetailSubscriber",
            component: DetailSubscriber,
            exact: true,
            path: "/detailSubscriber"
        },

        // {
        //     name: "AdminNotContactedYet",
        //     component: NotYetContacted,
        //     exact: true,
        //     path: "/admin/notcontact"
        // },
        // {
        //     name: "AdminAlreadyContacted",
        //     component: AlreadyContacted,
        //     exact: true,
        //     path: "/admin/already"
        // },
        // {
        //     name: "AdminDetailMerchant",
        //     component: DetailMerchant,
        //     exact: true,
        //     path: "/admin/detail"
        // },
        // {
        //     name: "AdminDashboard",
        //     component: SleepingMerchant,
        //     exact: true,
        //     path: "/admin/sleeping"
        // },
        // {
        //     name: "AdminOnlineSales",
        //     component: OnlineSales,
        //     exact: true,
        //     path: "/admin/onlinesales"
        // },
        // {
        //     name: "AdminSubscribers",
        //     component: Subscribers,
        //     exact: true,
        //     path: "/admin/subscribers"
        // },

    ];

    return (
        <BrowserRouter>
            <Switch>
                {authRoutes.map(route => <AuthRoute key={route.name} {...route} />)}
                {appRoutes.map(route => <ProtectedRoute key={route.name} {...route} />)}
                <Route path="/reset" component={() => <ResetPassword />} />
                <Route path="/addMerchant" component={() => <RegisterMerchant />} />
                <Route path="/apps" component={() => window.location = 'https://play.google.com/store/apps/details?id=com.pembukuandigital'} />
                <Route path="*" component={() => <NotFound />} />
            </Switch>
        </BrowserRouter>
    )
}

export default App
