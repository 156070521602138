import { Button, withStyles } from '@material-ui/core';
import React from 'react';

const ButtonPrimary = ({ text, onClick, disabled = false }) => {
    return (
        <ColorButton variant="contained" onClick={onClick} disabled={disabled}>
            {text}
        </ColorButton>
    )
}

const ColorButton = withStyles((theme) => ({
    root: {
        fontWeight: 'bold',
        color: theme.palette.getContrastText('#652f8e'),
        backgroundColor: '#652f8e',
        '&:hover': {
            backgroundColor: '#884bb7',
        },
        borderRadius: 50,
        width: '100%',
        height: 50
    },
}))(Button);

export default ButtonPrimary
